import groq from 'groq'
import { compareDesc, format, parseISO } from 'date-fns'

import {
  createSanityClientRead,
  ReviewModuleModes,
  uniqueOnly,
} from '@cellulargoods/core'
import { Sanity, Yotpo } from '@cellulargoods/types'

import { YotpoApi } from '../../api/yotpo'

type ProductByCategory = {
  _id: string
  product: {
    id: number
  }
}

const getProductsByCategory = groq`
  *[_type in ["product"] && category->slug.current == $category]{
    _id,
    product->{
      id
    }
  }
`

const getProductByName = groq`
  *[_type in ["product"] && product->title == $name]{
    product->{
      title
    },
    category->{
      slug{
        current
      }
    }
  }
`

const getAllSanityProducts = groq`
  *[_type == "product"]{
    title,
    product -> {
      id
    }
  }
`

export const mergeYotpoData = async <TDoc extends Sanity.Document>(
  document: TDoc,
  preview?: boolean
) => {
  const { CMSComponents } = document

  let cmsComps = CMSComponents

  const client = createSanityClientRead(preview)

  if (Array.isArray(cmsComps)) {
    cmsComps = await Promise.all(
      cmsComps.map(async (component) => {
        switch (component?._type) {
          case 'InstagramCarousel':
          case 'SocialChannelsPromotion':
          case 'UGCCarousel': {
            const { albumName } = component

            if (!albumName) {
              return component
            }

            const yotpoApi = new YotpoApi()

            const data = await yotpoApi.getPhotoAlbum(albumName)

            /**
             * get the product names, to avoid
             * overfetching make sure theyre unique
             */
            const uniqueProductNames = data
              .flatMap((datum) => datum.product?.name)
              .filter((datum) => Boolean(datum))
              .filter(uniqueOnly)

            /**
             * Fetch the related sanity data
             */
            const sanityProductData = (
              await Promise.all(
                uniqueProductNames.map(async (prod) => {
                  const products = await client.fetch(getProductByName, {
                    name: prod,
                  })

                  return products
                })
              )
            ).flat()

            /** */
            const dataWithCategory = data.map((datum) => ({
              ...datum,
              product: datum.product
                ? {
                    ...datum.product,
                    category:
                      sanityProductData.find(
                        (prod) => prod.product.title === datum.product?.name
                      )?.category.slug.current ?? null,
                  }
                : null,
            }))

            return {
              ...component,
              images: dataWithCategory,
            }
          }
          case 'ReviewCarousel':
          case 'ReviewsModule': {
            const { mode, count, stars } = component

            const yotpoApi = new YotpoApi()

            type Reviews =
              | Yotpo.ProductReviews
              | Yotpo.SiteReviews
              | Yotpo.CategoryReviews

            let reviewsData: Reviews = {} as Reviews
            let allProducts: Array<{ id: string; title: string }> = []

            if (mode === ReviewModuleModes.COMPANY) {
              /**
               * get all the company reviews
               */
              const data = await yotpoApi.getSiteReviews()

              const allSanityProducts = await client.fetch<any[]>(
                getAllSanityProducts
              )

              allProducts = allSanityProducts.map(({ title, product }) => ({
                title,
                id: product.id,
              }))

              reviewsData = data
            } else if (mode === ReviewModuleModes.CATEGORY) {
              /**
               * fetch each product id within the category
               * and then fetch the reviews for said product
               * organised by newest first
               */
              // TO DO – add type safety
              const products = await Promise.all(
                component.categories!.map(
                  async (cat) =>
                    await client.fetch<ProductByCategory[]>(
                      getProductsByCategory,
                      {
                        category: cat.slug!.current!,
                      }
                    )
                )
              )

              const reviews = await yotpoApi.getCategoryReviews(
                products.flat().map((prod) => prod.product.id),
                {
                  count: count!,
                  stars: stars!,
                }
              )

              reviewsData = reviews
            } else if (mode === ReviewModuleModes.PRODUCT) {
              /**
               * fetch the reviews for that particular product
               */
              const data = await yotpoApi.getProductReviews(
                component.product!.product!.id!,
                {
                  count: count!,
                  stars: stars!,
                }
              )

              reviewsData = data
            }

            return {
              ...component,
              cmsProduct: component.product,
              allProducts,
              score: reviewsData.score,
              totalReviews: reviewsData.totalReviews,
              reviews: reviewsData.reviews
                .map((r) => ({
                  ...r,
                  createdAt: parseISO(r.createdAt),
                }))
                .sort((a, b) => compareDesc(a.createdAt, b.createdAt))
                .map((r) => ({
                  ...r,
                  createdAt: format(r.createdAt, 'dd/MM/yy'),
                })),
            }
          }
          default:
            return component
        }
      })
    )
  }

  let productWithData: Sanity.Product | null | Sanity.ProductWithYotpo =
    document.product ?? null

  if (productWithData && productWithData.product?.id) {
    const { id } = productWithData.product

    const yotpoApi = new YotpoApi()

    const bottomLineData = await yotpoApi.getBottomLineScore(id)

    productWithData = {
      ...productWithData,
      reviews: bottomLineData,
    }
  }

  return {
    ...document,
    product: productWithData,
    CMSComponents: cmsComps ?? null,
  }
}
