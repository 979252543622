import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import { Klaviyo } from '@cellulargoods/types'
import { FullProfile } from '@cellulargoods/types/src/klaviyo'

const v2Request = axios.create({
  baseURL: 'https://a.klaviyo.com/api/v2',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const v1Request = axios.create({
  baseURL: 'https://a.klaviyo.com/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const KLAVIYO_PRIVATE_KEY = process.env.KLAVIYO_PRIVATE_KEY

export class KlaviyoApi implements Klaviyo.KlaviyoApi {
  /**
   * Subcribe to list will "double opt-in" the user
   * so the return 200 OK value is an empty array: `[]`
   *
   * https://apidocs.klaviyo.com/reference/lists-segments#subscribe
   *
   * This is used in the footer when signing up to marketing.
   *
   */
  subscribeProfileToList: Klaviyo.SubscribeProfileToList = async ({
    email,
    captureSource,
    dateCreated,
    firstName,
    lastName,
    dob,
    interestedIn,
    list,
    selected_region,
  }) => {
    try {
      const { data } = await v2Request.post<Klaviyo.Profile>(
        `/list/${list}/subscribe`,
        JSON.stringify({
          api_key: KLAVIYO_PRIVATE_KEY,
          profiles: [
            {
              first_name: firstName,
              last_name: lastName,
              ['Date of Birth']: dob,
              email,
              captureSource,
              dateCreated,
              ['Interests']: interestedIn,
              selected_region: selected_region,
              $consent: ['email'],
            },
          ],
        })
      )

      if (Array.isArray(data) && data.length === 0) {
        return {
          success: false,
        }
      } else {
        return {
          success: true,
        }
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      return {
        success: false,
      }
    }
  }

  subscribeProfileToLists: Klaviyo.SubscribeProfileToLists = async ({
    lists,
    ...restData
  }) => {
    try {
      const subscriptionSuccess = await Promise.all(
        lists.map((list) =>
          this.subscribeProfileToList({
            list,
            ...restData,
          })
        )
      )

      if (subscriptionSuccess.some((success) => !success.success)) {
        return {
          success: false,
        }
      } else {
        return {
          success: true,
        }
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      return {
        success: false,
      }
    }
  }

  subscribeEmailForProductUpdates: Klaviyo.SubscribeEmailForProductUpdates =
    async ({ list, email, captureSource, dateCreated, productName, type }) => {
      /**
       * Because if we post straight away with the data we'll
       * remove the current product interest from the user
       * we first need to try and get the user's profile
       * thus extracting the products they're already
       * interested in and then merge that list before posting.
       */
      const membersUrl = `/list/${list}/get-members`
      const subscribeUrl = `/list/${list}/subscribe`

      try {
        const { data: memberData } = await v2Request.post<Klaviyo.Profile[]>(
          membersUrl,
          JSON.stringify({
            api_key: KLAVIYO_PRIVATE_KEY,
            emails: [email],
          })
        )

        let products = [productName]

        if (memberData.length !== 0) {
          /**
           * We've got an existing person and need to extract their
           * current product interest before posting
           */
          const [user] = memberData

          const profilesUrl = `/person/${user.id}?api_key=${KLAVIYO_PRIVATE_KEY}`

          const { data: currentMemberData } = await v1Request.get<{
            [key: string]: any
          }>(profilesUrl)

          if (!currentMemberData[type].includes(productName)) {
            products = [productName, ...currentMemberData[type]]
          }
        } else {
          /**
           * we have a brand new person so we just want
           * to post our data and be done with it.
           */
        }

        const { data: subscribeData } = await v2Request.post<Klaviyo.Profile[]>(
          subscribeUrl,
          JSON.stringify({
            api_key: KLAVIYO_PRIVATE_KEY,
            profiles: [
              {
                email,
                captureSource,
                dateCreated,
                $consent: ['email'],
                [type]: products,
              },
            ],
          })
        )

        if (
          memberData.length !== 0 &&
          Array.isArray(subscribeData) &&
          subscribeData.length === 0
        ) {
          /**
           * if the profile existed we're still returned
           * a length of 1 which is a false fail
           */
          return {
            success: false,
          }
        } else {
          return {
            success: true,
          }
        }
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
        return { success: false }
      }
    }

  removeEmailFromList: Klaviyo.RemoveEmailFromList = async ({
    email,
    listId,
  }) => {
    const url = `/list/${listId}/subscribe`
    try {
      const res = await v2Request({
        method: 'DELETE',
        url,
        data: JSON.stringify({
          api_key: KLAVIYO_PRIVATE_KEY,
          emails: [email],
        }),
      })
      // Success is 200 OK.
      return res.status === 200
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      return false
    }
  }

  /**
   * addEmailToList will DIRECTLY add them to the list.
   *
   * There is no double-opt in email required. The return value is [{ id, email }]
   */
  addEmailToList: Klaviyo.AddEmailToList = async ({ email, listId }) => {
    const url = `/list/${listId}/members`
    try {
      const { data } = await v2Request({
        method: 'POST',
        url,
        data: JSON.stringify({
          api_key: KLAVIYO_PRIVATE_KEY,
          profiles: [
            {
              email,
            },
          ],
        }),
      })

      if (Array.isArray(data) && data.length === 1 && data[0].email === email) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      return false
    }
  }

  isEmailUnique: Klaviyo.IsEmailUnique = async ({ email, list }) => {
    if (email === '' || !list) {
      return { success: false }
    }

    const url = `/list/${list}/get-members`

    const { data } = await v2Request.post<Klaviyo.Profile[]>(
      url,
      JSON.stringify({
        api_key: KLAVIYO_PRIVATE_KEY,
        emails: [email],
      })
    )

    if (data.length === 0) {
      return { success: true }
    } else {
      return { success: false }
    }
  }

  getProfile: Klaviyo.GetProfile = async ({ email }) => {
    if (!email || email === '') {
      return { success: false }
    }

    const peopleUrl = `/people/search?api_key=${KLAVIYO_PRIVATE_KEY}&email=${encodeURIComponent(
      email
    )}`

    const { data: idData } = await v2Request.get<{ id: string }>(peopleUrl)

    if (idData.id) {
      const { data } = await v1Request.get<Klaviyo.FullProfile>(
        `/person/${idData.id}?api_key=${KLAVIYO_PRIVATE_KEY}`
      )

      if (data) {
        return {
          success: true,
          profile: data,
        }
      } else {
        return {
          success: false,
        }
      }
    } else {
      return {
        success: false,
      }
    }
  }

  updateProfile: Klaviyo.UpdateProfile = async ({ id, interests }) => {
    if (!id) {
      return { success: false }
    }

    /**
     * Submit an empty space so people
     * can get rid of all the options
     */
    const url = `/person/${id}?api_key=${KLAVIYO_PRIVATE_KEY}&Interests=${encodeURIComponent(
      interests.length > 0 ? interests.join(', ') : ' '
    )}`

    const { data } = await v1Request.put<FullProfile>(url)

    if (data?.id === id) {
      return { success: true, profile: data }
    } else {
      return { success: false }
    }
  }
}
