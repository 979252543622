import groq from 'groq'
import { IMAGE } from '../utils/image'

export const PORTRAIT_CAROUSEL = groq`
  _type,
  _key,
  title,
  items[]{
    _key,
    label,
    image {
      ${IMAGE}
    }
  }
`
