import styled from 'styled-components'

import {
  FONT_STYLE_SOFIA_14_500,
  hideScrollBar,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'
import { Text } from '@cellulargoods/core'

const hints = ['Face oil', 'Face serum', 'After Shaving Moisturiser']

interface SearchHintsProps {
  onHintClick: (value: string) => void
}

export const SearchHints = ({ onHintClick }: SearchHintsProps) => {
  const handleHintClick = (hint: string) => () => {
    onHintClick(hint)
  }

  return (
    <Wrap>
      <SearchPromptText>Try searching for:</SearchPromptText>
      <HintsWrap>
        {hints.map((hint) => (
          <HintItem
            data-testid="search-hint"
            key={hint}
            onClick={handleHintClick(hint)}
          >
            <span>{hint}</span>
          </HintItem>
        ))}
      </HintsWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row;
    padding: 0 4rem 4rem;
    margin-top: 12px;
  }
`

const HintsWrap = styled.div`
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  align-items: center;
  ${hideScrollBar};
`

const SearchPromptText = styled(Text)`
  color: var(--accessibleGrey);
  margin-bottom: 20px;
  margin-right: 20px;

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 0;
  }
`

const HintItem = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: var(--black);
  padding: 0.5rem 1.8rem;
  border: 1px solid var(--black);

  & + & {
    margin-left: 10px;
  }

  & > span {
    position: relative;
    bottom: 0.2rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    background-color: var(--lightBrown);
    border-top: none;
    width: 100%;
    height: 0;
    transition: height 200ms ease-out;
  }

  @media (hover: hover) {
    &:hover:before {
      height: 100%;
    }
  }
`
