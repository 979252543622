import { CheckCircle, Info, XCircle } from 'phosphor-react'
import { ReactNode } from 'react'

import styled, { css } from 'styled-components'

type Intents = 'success' | 'info' | 'fail'

const IntentsColorMap = {
  success: css`
    background: var(--validationSuccess);
    color: white !important;
  `,
  info: css`
    background: rgba(0, 0, 0, 0.05);
    color: var(--darkGrey1) !important;
  `,
  fail: css`
    background: var(--validationError);
    color: var(--white) !important;
  `,
}

const Wrap = styled.div<{
  intent: Intents
}>`
  padding: 20px 2rem;
  display: flex;
  margin-bottom: 30px;

  ${({ intent }) => IntentsColorMap[intent]}
`

const IconWrap = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export interface Props {
  children: ReactNode
  intent?: Intents
  className?: string
}

const Alert = ({ children, className, intent = 'info' }: Props) => {
  return (
    <Wrap data-testid={`alert-${intent}`} intent={intent} className={className}>
      <IconWrap>
        {intent === 'info' ? (
          <Info size={20} />
        ) : 'fail' ? (
          <CheckCircle size={20} />
        ) : (
          <XCircle size={20} />
        )}
      </IconWrap>
      <div>{children}</div>
    </Wrap>
  )
}

export default Alert
