import groq from 'groq'
import { IMAGE } from '../utils/image'

export const TEAM_GRID = groq`
  _key,
  _type,
  showBio,
  items[]->{
    _id,
    name,
    jobTitle,
    text,
    relatedLinkHref,
    image{
      ${IMAGE}
    }
  }
`
