import { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Bg,
  Button,
  ButtonTypes,
  Input,
  Text,
  validateValues,
} from '@cellulargoods/core'
import { getFontStyles, FONT_STYLE_SOFIA_14_400 } from '@cellulargoods/styles'

import { useShopifyCustomer } from 'hooks/useShopifyCustomer'

import { STRINGS } from 'references/locale'

import Alert from 'components/Cards/Alert'

import isEmail from 'validator/lib/isEmail'

const LOGIN_BASE_STATE = {
  values: {
    email: '',
    password: '',
  },
  errors: {
    email: '',
    password: '',
  },
  success: false,
  submitting: false,
  error: '',
}

type LoginBaseState = typeof LOGIN_BASE_STATE

type ModalAuthLoginProps = {
  onForgotClick?: () => void
  visible?: boolean
  onSubmitted?: () => void
}

export const ModalAuthLogin = ({
  onForgotClick,
  visible,
  onSubmitted,
}: ModalAuthLoginProps) => {
  const [{ values, errors, submitting, success, error }, setFormState] =
    useState<LoginBaseState>(LOGIN_BASE_STATE)

  const { loginCustomer } = useShopifyCustomer()

  useEffect(() => {
    if (!visible) {
      setFormState(LOGIN_BASE_STATE)
    }
  }, [visible])

  const handleInputChange = (fieldName: string) => (value: string) => {
    setFormState((s) => ({
      ...s,
      errors: {
        ...s.errors,
        [fieldName]: '',
      },
      values: {
        ...s.values,
        [fieldName]: value,
      },
    }))
  }

  const handleForgotPasswordClick = () => {
    if (onForgotClick) {
      onForgotClick()
    }
  }

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const { email, password } = values

    setFormState((s) => ({
      ...s,
      success: false,
      error: '',
      submitting: true,
      errors: LOGIN_BASE_STATE.errors,
    }))

    /**
     * check there are some values
     */
    const { errors, valid } = validateValues(
      {
        email,
        password,
      },
      (key, value) => (key === 'email' ? isEmail(value as string) : true)
    )

    if (!valid) {
      setFormState((s) => ({
        ...s,
        submitting: false,
        errors: {
          ...s.errors,
          ...Object.entries(errors).reduce((acc, [key, value]) => {
            if (value) {
              switch (key as keyof typeof errors) {
                case 'email':
                  acc.email = STRINGS['login-account.form.error.email']
                  break
                case 'password':
                  acc.password = STRINGS['login-account.form.error.password']
                  break
              }
            }

            return acc
          }, {} as LoginBaseState['errors']),
        },
      }))
      return
    } else {
      try {
        const { success, customerErrors, error } = await loginCustomer({
          email,
          password,
        })

        if (success) {
          setFormState({ ...LOGIN_BASE_STATE, success: true })

          if (onSubmitted) {
            onSubmitted()
          }
        } else if (customerErrors) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            errors: { ...LOGIN_BASE_STATE.errors, ...customerErrors },
          }))
        } else if (error) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            error,
          }))
        }
      } catch (err) {
        console.error(err)
        setFormState((s) => ({ ...s, submitting: false }))
      }
    }
  }

  return (
    <>
      {success ? (
        <Alert intent="success">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>
            {STRINGS['login-account.form.success']}
          </AlertText>
        </Alert>
      ) : error ? (
        <Alert intent="fail">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>{error}</AlertText>
        </Alert>
      ) : null}
      <Form onSubmit={handleFormSubmit}>
        <div>
          <Input
            label="Your email"
            type="email"
            value={values.email}
            onChange={handleInputChange('email')}
            autoComplete="email"
            error={errors.email}
            disabled={!visible || submitting}
            testId="login-email"
          />
          <Input
            label="Password"
            type="password"
            value={values.password}
            onChange={handleInputChange('password')}
            autoComplete="current-password"
            error={errors.password}
            disabled={!visible || submitting}
            testId="login-password"
          />
          <ForgotLink
            disabled={!visible || submitting}
            type="button"
            onClick={handleForgotPasswordClick}
          >
            Forgot Password?
          </ForgotLink>
        </div>
        <Button
          type="submit"
          variant={ButtonTypes.PRIMARY}
          isFullWidth
          bg={Bg.black}
          disabled={!visible || submitting}
          testId="login-submit"
        >
          Login
        </Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`

const ForgotLink = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_14_400)};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  float: right;
  text-align: right;
  display: block;
  color: var(--darkGrey2);
  text-decoration: underline;
`

const AlertText = styled(Text)`
  color: var(--white);

  & + & {
    margin-top: 1.5rem;
  }
`
