import groq from 'groq'
import { MEDIA } from '../utils/media'

import { TAG } from './tag'

export const INTERNAL_ARTICLE_CARD = groq`
  _id,
  _type,
  title,
  slug {
    current
  },
  Tags[]->{
    ${TAG}
  },
  ArticleCardInfo {
    excerpt,
    media[]{
      ${MEDIA}
    }
  }
`

export const EXTERNAL_ARTICLE_CARD = groq`
  _id,
  _type,
  title,
  link,
  file {
    asset->{
      url
    }
  },
  Tags[]->{
    ${TAG}
  },
  ArticleCardInfo {
    excerpt,
    media[]{
      ${MEDIA}
    }
  }
`

export const ARTICLE_CARD = groq`
    _type,
    _key,
    reference->{
      (_type == 'internalArticle') => {
          ${INTERNAL_ARTICLE_CARD}
      },
      (_type == 'externalArticle') => {
          ${EXTERNAL_ARTICLE_CARD}
      },
    }
`
