import groq from 'groq'

export const SOCIALS_CHANNEL_PROMOTION = groq`
  _type,
  _key,
  title,
  albumName,
  socialLinks[]->{
    label,
    url,
  },
`
