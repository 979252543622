import groq from 'groq'
import { MEDIA } from '../utils/media'
import { CTA_BUTTON } from '../utils/ctaButton'

export const HERO_IMAGE = groq`
  _key,
  _type,
  title,
  title_width,
  subtitle,
  text,
  CTAButton{
    ${CTA_BUTTON}
  },
  desktop_media[]{
    ${MEDIA}
  },
  mobile_media[]{
    ${MEDIA}
  }
`
