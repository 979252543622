import groq from 'groq'
import { IMAGE } from '../utils/image'
import { MEDIA } from '../utils/media'

const LINK = groq`
  "label": link.label,
  "linkType": link.linkType,
  (link.linkType == 'internal' && link.linkInternalString != null) => {
    "link": link.linkInternalString
  },
  (link.linkType == 'internal' && link.linkInternal != null) => {
    "referenceType": link.linkInternal->_type,
    "link": link.linkInternal->slug.current,
    (link.linkInternal->_type == "functionalProductDisplayPage" || link.linkInternal->_type == "enhancedProductDisplayPage") => {
      "link": link.linkInternal->product->slug.current,
      "category": link.linkInternal->product->category->slug.current,
    },
    (link.linkInternal->_type == 'internalArticle') => {
      "category": link.linkInternal->Tags[0]->slug.current,
    },
  },
  (link.linkType == 'external') => {
    "link": link.linkExternal,
  },
`

export const NAVIGATION = groq`
    *[_type == "navigation" && !(_id in path("drafts.**"))] | order(_updatedAt desc)[0]{
        _id,
        navbar[] {
          _key,
          _type,
          label,
          ${LINK}
          children[]{
            _key,
            _type,
            comingSoon,
            "media": media[0]{
              ${MEDIA}
            },
            (media == null && link.linkInternal->PageMeta.image != null) => {
              "media": link.linkInternal->PageMeta.image {
                ${IMAGE}
              }
            },
            (media == null && link.linkInternal->ArticleCardInfo != null) => {
              "media": link.linkInternal->ArticleCardInfo.media[0]{
                ${MEDIA}
              },
            },
            ${LINK}
          },
        },
        mobileSubNav[]{
            _key,
            (reference != null) => {
              "title": reference->title,
              "slug": reference->slug.current,
            },
            (reference == null) => {
              "title": label,
               "slug": link,
            },
            (socialLink != null) => {
              "title": socialLink->label,
              "slug": socialLink->url,
            }
        }
    }
`
