import groq from 'groq'
import { MEDIA } from '../utils/media'

export const ONE_TILE_PRODUCT_PROMO = groq`
  _id,
  _key,
  _type,
  sliderImages[]{
    ${MEDIA}
  },
  tabs[]{
    _key,
    _type,
    label,
    text,
  }
`
