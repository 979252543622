import { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Text,
  Bg,
  Button,
  ButtonTypes,
  Input,
  validateValues,
} from '@cellulargoods/core'
import { FONT_STYLE_SOFIA_14_400 } from '@cellulargoods/styles'

import Alert from 'components/Cards/Alert'

import { useShopifyCustomer } from 'hooks/useShopifyCustomer'

import { STRINGS } from 'references/locale'

import isEmail from 'validator/lib/isEmail'

const FORGOT_BASE_STATE = {
  values: {
    email: '',
  },
  errors: {
    email: '',
  },
  submittedEmail: '',
  success: false,
  error: '',
  submitting: false,
}

type ForgotBaseState = typeof FORGOT_BASE_STATE

type ModalAuthForgotPasswordProps = {
  visible?: boolean
}

export const ModalAuthForgotPassword = ({
  visible,
}: ModalAuthForgotPasswordProps) => {
  const [
    { values, errors, submitting, error, success, submittedEmail },
    setFormState,
  ] = useState<ForgotBaseState>(FORGOT_BASE_STATE)

  const { recoverCustomer } = useShopifyCustomer()

  useEffect(() => {
    if (!visible) {
      setFormState(FORGOT_BASE_STATE)
    }
  }, [visible])

  const handleInputChange =
    (fieldName: string) => (value: string | boolean) => {
      setFormState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [fieldName]: '',
        },
        values: {
          ...s.values,
          [fieldName]: value,
        },
      }))
    }

  const handleSubmit = async (e: FormEvent) => {
    const { email } = values

    e.preventDefault()

    setFormState((s) => ({
      ...s,
      submitting: true,
      success: false,
      submittedEmail: '',
      errors: FORGOT_BASE_STATE.errors,
    }))

    const { valid } = validateValues(
      {
        email,
      },
      (_, value) => isEmail(value as string)
    )
    if (!valid) {
      setFormState((s) => ({
        ...s,
        submitting: false,
        errors: {
          email: STRINGS['forgot-account.form.error.email'],
        },
      }))
      return
    } else {
      try {
        const { success, customerErrors, error } = await recoverCustomer({
          email,
        })

        if (success) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            success: true,
            submittedEmail: values.email,
          }))
        } else if (customerErrors) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            errors: {
              ...s.errors,
              ...customerErrors,
            },
          }))
        } else if (error) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            error,
          }))
        }
      } catch (err) {
        console.error(err)
        setFormState((s) => ({ ...s, submitting: false }))
      }
    }
  }

  return (
    <>
      {success ? (
        <Alert intent="success">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>
            {`${STRINGS['forgot-account.form.success.0']}${submittedEmail}`}
          </AlertText>
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>
            {STRINGS['forgot-account.form.success.1']}
          </AlertText>
        </Alert>
      ) : error ? (
        <Alert intent="fail">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>{error}</AlertText>
        </Alert>
      ) : null}
      <Form onSubmit={handleSubmit}>
        <Input
          disabled={!visible || submitting}
          type="email"
          value={values.email}
          onChange={handleInputChange('email')}
          autoComplete="email"
          error={errors.email}
          label="Your email"
          testId="forgot-input"
        />
        <Button
          type="submit"
          variant={ButtonTypes.PRIMARY}
          isFullWidth
          bg={Bg.black}
          disabled={!visible || submitting}
        >
          Reset password
        </Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`

const AlertText = styled(Text)`
  color: var(--white);

  & + & {
    margin-top: 1.5rem;
  }
`
