import groq from 'groq'

import { PAGE_META } from '../snippets/pageMeta'
import { PAGE_SETTINGS } from '../snippets/pageSettings'

import { ALL_CMS_COMPONENTS } from '../components/allCMSComponents'

export const GENERIC_DOCUMENT = groq`
    title,
    _type,
    slug,
    PageMeta {
        ${PAGE_META}
    },
    PageSettings {
        ${PAGE_SETTINGS}
    },
    CMSComponents[]{
        ${ALL_CMS_COMPONENTS}
    }
`
