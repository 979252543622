import groq from 'groq'

export const UTILITY_PAGE_BY_SLUG = `_type == "utilityPage" && slug.current == $slug`

export const UTILITY_NAVIGATION = groq`
    *[_type == "utilityNavigation"][0]{
        UtilityNavigationLink[]{
            _type,
            label,
            reference->{
                _type,
                slug{
                    current
                }
            }
        }
    }
`

export type AllUtilitySlugs = Array<{
  slug: {
    current: string
  }
}>

export const ALL_UTILITY_SLUGS = groq`
    *[_type == "utilityPage"]{
        slug {
            current
        }
    }
`

export const UTILITY_PAGE = groq`
    _id,
    _type,
    title,
    slug {
        current,
    },
    PageSettings {
        _key,
        _type,
        theme,
        transparentHeader
    },
    PageMeta {
        description,
        image {
            _key,
            _type,
            asset->{
                url,
                altText,
                metadata {
                dimensions {
                    width,
                    height
                }
            }
        }
        },
        title
    },
    "content": UtilityContent
`
