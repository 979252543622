import groq from 'groq'
import { ARTICLE_CARD } from './articleCard'

export const ARTICLE_HIGHLIGHTER = groq`
  _key,
  _type,
  articles[]{
    ${ARTICLE_CARD}
  }
`
