import styled from 'styled-components'
import { Text, ensurePriceHasDecimals } from '@cellulargoods/core'
import { FONT_STYLE_SOFIA_14_400 } from '@cellulargoods/styles'
import { useCurrency, useExchangeRate } from '@cellulargoods/hooks'

interface Props {
  subTotal: number
}

const CartMetaContent = ({ subTotal = 0 }: Props) => {
  const exchangeRates = useExchangeRate()
  const [currency] = useCurrency()

  return (
    <Wrap>
      {subTotal > 0 && (
        <Content>
          <Text fontStyle={FONT_STYLE_SOFIA_14_400}>Shipping</Text>
          <Text fontStyle={FONT_STYLE_SOFIA_14_400}>Free (Standard)</Text>
        </Content>
      )}
      <Content>
        <Text fontStyle={FONT_STYLE_SOFIA_14_400}>Subtotal (Inc. VAT)</Text>
        <Text fontStyle={FONT_STYLE_SOFIA_14_400}>
          {currency === 'USD'
            ? '$' + Math.ceil(subTotal * exchangeRates[currency][0]).toFixed(2)
            : currency === 'EUR'
            ? '€' + Math.ceil(subTotal * exchangeRates[currency][0]).toFixed(2)
            : ensurePriceHasDecimals(subTotal)}
        </Text>
      </Content>
    </Wrap>
  )
}

export default CartMetaContent

const Wrap = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding-top: 2rem;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & + & {
    margin-top: 20px;
    border-top: 1px solid var(--midGrey);
    padding-top: 20px;
  }
`
