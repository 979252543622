import styled from 'styled-components'

import { Carousel, CarouselFlex } from '@cellulargoods/core'
import { Sanity } from '@cellulargoods/types'

import { ProductSuggestionCard } from 'components/Cards/ProductSuggestionCard'

export type ProductSuggestionsProps = {
  items?: Sanity.ProductCollection
  shouldRecalcPositions?: boolean
  onClick?: () => void
}

export const ProductSuggestions = ({
  items,
  onClick,
}: ProductSuggestionsProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  if (!items || items?.selected_products?.length === 0) {
    return null
  }

  return (
    <ProductSuggestionContainer>
      {items.selected_products && (
        <ProductSuggestionCarousel>
          {items.selected_products.map((prod, i) => (
            <ProductSuggestionCarouselCard
              key={i}
              onClick={handleClick}
              {...prod}
            />
          ))}
        </ProductSuggestionCarousel>
      )}
    </ProductSuggestionContainer>
  )
}

const ProductSuggestionContainer = styled.div`
  margin-bottom: 4rem;
`

const ProductSuggestionCarousel = styled(Carousel)`
  overflow: hidden;
  ${CarouselFlex} {
    padding: 0;
  }
`

const ProductSuggestionCarouselCard = styled(ProductSuggestionCard)`
  & + & {
    margin-left: 2rem;
  }
`
