export const setCookie = <TValue>(
  name: string,
  value: TValue,
  mins: number
) => {
  let expires = ''

  if (mins) {
    const date = new Date()
    date.setTime(date.getTime() + mins * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = `${name}=${value || ''}${expires};`
}

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    if (cookie.indexOf(name + '=') > -1) {
      return cookie.split('=')[1]
    }
  }

  return null
}
