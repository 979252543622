import groq from 'groq'

import { LINK } from '../utils/link'
import { MEDIA } from '../utils/media'

import { HEADER_TEXT } from './headerText'

export const THREE_LINK_GRID = groq`
    _key,
    _type,
    "header": HeaderText{
        ${HEADER_TEXT}
    },
    items[]{
        _key,
        title,
        copy,
        link {
            ${LINK}
        },
        media[] {
            ${MEDIA}
        }
    }
`
