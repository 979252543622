import groq from 'groq'
import { HEADER_TEXT } from './headerText'

export const FAQ_ACCORDION = groq`
 _key,
 _type,
 HeaderText {
  ${HEADER_TEXT}
 },
 items[] {
   _key,
   label,
   text,
   faq_reference -> {
     _id,
     question,
     answer
   }
 }
`
