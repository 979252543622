import groq from 'groq'

export const FOOTER = groq`
    *[_type == "footer" && !(_id in path("drafts.**"))] | order(_updatedAt desc)[0]{
        sections[]{
            _key,
            _type,
            title,
            items[]{
              _key,
              _type,
              label,
              link,
              reference->{
                  _id,
                  _type,
                  title,
                  slug{
                      current
                  },
              },
                socialLink->{
                    _id,
                    _type,
                    label,
                    url
                }
            }
        },
    }
`
