import styled from 'styled-components'
import { Trash } from 'phosphor-react'

import { Sanity, Maybe, Shopify } from '@cellulargoods/types'
import {
  Text,
  Image,
  ensurePriceHasDecimals,
  IconButton,
  SoldOutOrnament,
} from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_14_500,
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
  MEDIA_QUERIES,
  aspectRatio,
} from '@cellulargoods/styles'

import { QuantitySelector } from 'components/Input/QuantitySelector'
import { ButtonTertiary } from '@cellulargoods/core/src/components/Button/ButtonTertiary'
import { useCart } from 'hooks/useCart'
import { useCurrency, useExchangeRate } from '@cellulargoods/hooks'
import { EVERYTHING_IS_SOLD_OUT } from 'references/constants'

interface Props extends Shopify.CheckoutLineItemFragment {
  image: Maybe<Sanity.MediaImage> | undefined
  isCartPage?: boolean
}

const CartItem = ({
  title,
  variant,
  quantity,
  image,
  isCartPage = false,
  ...rest
}: Props) => {
  const [currency] = useCurrency()
  const exchangeRates = useExchangeRate()
  const { lineItemRemove, lineItemMutatingID, lineItemUpdate } = useCart()

  const isGWP =
    rest?.customAttributes?.findIndex(
      (i) => i.value === 'Gift with Purchase'
    ) >= 0

  if (!variant) {
    return null
  }

  /**
   * Note:
   *
   * When removing an item from the cart, you must provide the **`lineItem`** ID.
   * Not the `variant.id`
   *
   * This is confusing, because you have to add the `variantId` to the `lineItems` variable
   * when creating a checkout.
   */
  const handleRemoveItem = async () => {
    try {
      await lineItemRemove({
        productVariantId: variant.id,
        keepCartClose: isCartPage,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleChangeQuantity = async (newQuantity: number) => {
    try {
      await lineItemUpdate({
        productVariantId: variant.id,
        quantity: newQuantity,
        keepCartClose: isCartPage,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeleteProduct = () => handleRemoveItem()

  const availableForSale =
    !EVERYTHING_IS_SOLD_OUT && Boolean(variant.availableForSale)
  const quantityAvailable = variant.quantityAvailable

  const price =
    currency === 'USD'
      ? '$' +
        Math.ceil(
          Number(ensurePriceHasDecimals(variant.priceV2.amount).split('£')[1]) *
            exchangeRates[currency][0]
        ).toFixed(2)
      : currency === 'EUR'
      ? '€' +
        Math.ceil(
          Number(ensurePriceHasDecimals(variant.priceV2.amount).split('£')[1]) *
            exchangeRates[currency][0]
        ).toFixed(2)
      : ensurePriceHasDecimals(variant.priceV2.amount)

  const compareAtPrice =
    variant?.compareAtPriceV2?.amount === 0
      ? undefined
      : currency === 'USD'
      ? '$' +
        Math.ceil(
          Number(
            ensurePriceHasDecimals(variant?.compareAtPriceV2?.amount).split(
              '£'
            )[1]
          ) * exchangeRates[currency][0]
        ).toFixed(2)
      : ensurePriceHasDecimals(variant?.compareAtPriceV2?.amount)

  const renderAction = () => {
    if (availableForSale) {
      return (
        <QuantitySelector
          maxQuantityLimit={quantityAvailable}
          minusToRemove
          onRemoved={handleRemoveItem}
          quantity={quantity}
          onChangeQuantity={handleChangeQuantity}
          loading={lineItemMutatingID === variant.id}
        />
      )
    } else {
      return (
        <ItemIconButton
          onClick={handleDeleteProduct}
          ariaLabel={`Remove ${title} from cart`}
        >
          <Trash size={24} weight="thin" />
        </ItemIconButton>
      )
    }
  }

  return (
    <Wrap>
      <ImageAndContentWrap>
        <ImageWrap>
          {image && (
            <Image
              image={image}
              layout="fill"
              objectFit="cover"
              sizes={['18vw', null, null, '8vw']}
            />
          )}
        </ImageWrap>
        <Content productIsAvailable={availableForSale}>
          <div>
            <Text fontStyle={FONT_STYLE_SOFIA_16_500}>{title}</Text>
            {availableForSale ? (
              variant && (
                <VariantInfo fontStyle={FONT_STYLE_SOFIA_14_500}>
                  <PriceContainer>
                    {variant?.compareAtPriceV2 && compareAtPrice ? (
                      <RRP>RRP £{compareAtPrice}</RRP>
                    ) : null}
                    {isGWP ? <RRP>{price}</RRP> : null}
                    {`${isGWP ? price[0] + '0.00' : price}${' '}${
                      variant.title !== 'Default Title'
                        ? `• ${variant.title}`
                        : ''
                    }`}
                  </PriceContainer>
                </VariantInfo>
              )
            ) : (
              <ProductSoldOut small />
            )}
          </div>
          <RemoveButton onClick={handleRemoveItem}>Remove</RemoveButton>
          <ShowDesktop
            isCartPage={isCartPage}
            productIsAvailable={availableForSale}
          >
            {renderAction()}
          </ShowDesktop>
        </Content>
      </ImageAndContentWrap>
      <ShowMobile isCartPage={isCartPage} productIsAvailable={availableForSale}>
        {renderAction()}
      </ShowMobile>
    </Wrap>
  )
}

export default CartItem

const RemoveButton = styled(ButtonTertiary)`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  color: var(--accessibleGrey);
  margin-bottom: 20px;
  height: 20px;
  border-bottom: none;
`

const Wrap = styled.li`
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
  flex-direction: column;

  & + & {
    border-top: 1px solid var(--midGrey);
    padding-top: 20px;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row;
  }
`

const Content = styled.div<{
  productIsAvailable: boolean
}>`
  width: 100%;

  ${(props) =>
    !props.productIsAvailable &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`

const VariantInfo = styled(Text)`
  margin-top: 10px;
  margin-bottom: 30px;
`

const ImageWrap = styled.div`
  background-color: var(--softGrey);
  position: relative;
  min-width: 7rem;
  ${aspectRatio(70, 100, false)};

  ${MEDIA_QUERIES.desktopUp} {
    min-width: 12rem;
    ${aspectRatio(120, 170, false)}
  }
`

const ImageAndContentWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 17px;
  ${MEDIA_QUERIES.desktopUp} {
    gap: 21px;
  }
`
const PriceContainer = styled.span`
  display: flex;
`

const RRP = styled.p`
  color: var(--accessibleGrey);
  text-decoration: line-through;
  ${MEDIA_QUERIES.tabletUp} {
    min-width: 8rem;
  }
`

const ShowDesktop = styled.div<{
  productIsAvailable: boolean
  isCartPage: boolean
}>`
  display: ${(props) => (!props.productIsAvailable ? 'block' : 'none')};

  ${MEDIA_QUERIES.tabletUp} {
    display: ${(props) => (props.isCartPage ? 'block' : 'none')};
  }

  ${MEDIA_QUERIES.desktopUp} {
    display: block;
  }
`

const ShowMobile = styled.div<{
  productIsAvailable: boolean
  isCartPage: boolean
}>`
  display: ${(props) => (props.productIsAvailable ? 'block' : 'none')};

  ${MEDIA_QUERIES.tabletUp} {
    display: ${(props) => (props.isCartPage ? 'none' : 'block')};
  }

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const ItemIconButton = styled(IconButton)``

const ProductSoldOut = styled(SoldOutOrnament)`
  display: inline-block;
  margin-top: 0.5rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 1rem;
  }
`
