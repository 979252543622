import groq from 'groq'
import { PRODUCT_FRAGMENT } from './productFragment'

export const RELATED_PRODUCTS = groq`
  _key,
  _type,
  title,
  "relatedProducts": products->{
    _id,
    title,
    selected_products[]->{
      ${PRODUCT_FRAGMENT}
    }
  }
`
