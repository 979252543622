import groq from 'groq'
import { CTA_BUTTON } from '../utils/ctaButton'

export const INSTAGRAM_CAROUSEL = groq`
  _type,
  _key,
  albumName,
  title,
  CTAButton {
    ${CTA_BUTTON}
  },
`
