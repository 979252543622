import groq from 'groq'
import { IMAGE } from '../utils/image'

export const PAGE_META = groq`
    description,
    image {
        ${IMAGE}
    },
    title
`
