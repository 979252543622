import { FormEvent, useEffect, useState } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import {
  Bg,
  Button,
  ButtonTypes,
  Input,
  CheckboxInput,
  Text,
  validateValues,
} from '@cellulargoods/core'
import { FONT_STYLE_SOFIA_14_400 } from '@cellulargoods/styles'

import { useShopifyCustomer } from 'hooks/useShopifyCustomer'

import { UtilityPageSlugs } from 'references/constants'
import { STRINGS } from 'references/locale'

import { testPassword } from 'helpers/accounts'

import Alert from 'components/Cards/Alert'

import isEmail from 'validator/lib/isEmail'

const CREATE_BASE_STATE = {
  values: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptsMarketing: false,
    termsAccepted: false,
  },
  errors: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    termsAccepted: false,
  },
  success: false,
  submitting: false,
  error: '',
}

type CreateBaseState = typeof CREATE_BASE_STATE

type ModalAuthCreateAccountProps = {
  visible?: boolean
  onSubmitted?: () => void
}

export const ModalAuthCreateAccount = ({
  visible,
  onSubmitted,
}: ModalAuthCreateAccountProps) => {
  const [{ values, errors, submitting, success, error }, setFormState] =
    useState<CreateBaseState>(CREATE_BASE_STATE)

  const { createCustomer, loginCustomer } = useShopifyCustomer()

  useEffect(() => {
    if (!visible) {
      setFormState(CREATE_BASE_STATE)
    }
  }, [visible])

  const handleInputChange =
    (fieldName: string) => (value: string | boolean) => {
      const errorName = fieldName === 'confirmPassword' ? 'password' : fieldName
      setFormState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [errorName]: false,
        },
        values: {
          ...s.values,
          [fieldName]: value,
        },
      }))
    }

  const handleFormSubmit = async (e: FormEvent) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      termsAccepted,
      acceptsMarketing,
    } = values

    e.preventDefault()

    setFormState((s) => ({
      ...s,
      success: false,
      error: '',
      submitting: true,
      errors: CREATE_BASE_STATE.errors,
    }))

    const { errors, valid } = validateValues(
      {
        firstName,
        lastName,
        email,
        password,
        termsAccepted,
      },
      (name, value) => {
        switch (name) {
          case 'email':
            return isEmail(value as string)
          case 'password':
            return testPassword(value as string) && value === confirmPassword
          case 'termsAccepted':
            return value as boolean
          default:
            return true
        }
      }
    )

    if (!valid) {
      setFormState((s) => ({
        ...s,
        submitting: false,
        errors: {
          ...s.errors,
          ...Object.entries(errors).reduce((acc, [key, value]) => {
            if (value) {
              switch (key as keyof typeof errors) {
                case 'firstName':
                  acc.firstName = STRINGS['create-account.form.error.firstName']
                  break
                case 'lastName':
                  acc.lastName = STRINGS['create-account.form.error.lastName']
                  break
                case 'email':
                  acc.email = STRINGS['create-account.form.error.email']
                  break
                case 'password':
                  acc.password = STRINGS['create-account.form.error.password']
                  break
                case 'termsAccepted':
                  acc.termsAccepted = value
              }
            }

            return acc
          }, {} as CreateBaseState['errors']),
        },
      }))
      return
    } else {
      try {
        const { success, error, customerErrors } = await createCustomer({
          firstName,
          lastName,
          email,
          password,
          acceptsMarketing,
        })

        if (success) {
          /**
           * all good, reset the form
           */
          setFormState({
            ...CREATE_BASE_STATE,
            success,
          })

          const loginResult = await loginCustomer({
            email,
            password,
          })

          if (loginResult.success && onSubmitted) {
            onSubmitted()
          }
        } else if (customerErrors) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            errors: { ...CREATE_BASE_STATE.errors, ...customerErrors },
          }))

          return
        } else if (error) {
          setFormState((s) => ({
            ...s,
            submitting: false,
            error,
          }))

          return
        }
      } catch (err) {
        console.error(err)
        setFormState((s) => ({ ...s, submitting: false }))
      }
    }
  }

  return (
    <>
      {success ? (
        <Alert intent="success">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>
            {STRINGS['create-account.form.success']}
          </AlertText>
        </Alert>
      ) : error ? (
        <Alert intent="fail">
          <AlertText fontStyle={FONT_STYLE_SOFIA_14_400}>{error}</AlertText>
        </Alert>
      ) : null}
      <Form onSubmit={handleFormSubmit}>
        <div>
          <InputRow>
            <Input
              label="First name"
              type="text"
              value={values.firstName}
              onChange={handleInputChange('firstName')}
              autoComplete="given-name"
              disabled={!visible || submitting}
              error={errors.firstName}
              name="firstName"
            />
            <Input
              label="Last name"
              type="text"
              value={values.lastName}
              onChange={handleInputChange('lastName')}
              autoComplete="family-name"
              disabled={!visible || submitting}
              error={errors.lastName}
              name="lastName"
            />
          </InputRow>
          <Input
            label="Email"
            type="email"
            value={values.email}
            onChange={handleInputChange('email')}
            autoComplete="email"
            disabled={!visible || submitting}
            error={errors.email}
            name="email"
          />
          <Input
            label="Password"
            type="password"
            value={values.password}
            onChange={handleInputChange('password')}
            autoComplete="new-password"
            disabled={!visible || submitting}
            error={errors.password}
            name="password"
          />
          <Input
            label="Confirm Password"
            type="password"
            value={values.confirmPassword}
            onChange={handleInputChange('confirmPassword')}
            autoComplete="new-password"
            disabled={!visible || submitting}
            error={errors.password}
            name="confirmPassword"
          />
        </div>
        <div>
          <CheckboxWrap>
            <CheckboxInput
              checked={values.acceptsMarketing}
              onChange={handleInputChange('acceptsMarketing')}
              label="Sign up for early access to new products, expert reads and special offers"
              disabled={!visible || submitting}
            />
            <CheckboxInput
              checked={values.termsAccepted}
              onChange={handleInputChange('termsAccepted')}
              label={<TermsLabel disabled={!visible || submitting} />}
              error={errors.termsAccepted}
              disabled={!visible || submitting}
              testId="create-terms"
            />
          </CheckboxWrap>
          <Button
            type="submit"
            variant={ButtonTypes.PRIMARY}
            isFullWidth
            bg={Bg.black}
            disabled={!visible || submitting}
            testId="create-submit"
          >
            Create account
          </Button>
        </div>
      </Form>
    </>
  )
}

const TermsLabel = ({ disabled }: { disabled: boolean }) => {
  return (
    <span>
      {`By creating an account, you accept the `}
      <Link href={`/${UtilityPageSlugs.terms}`} passHref>
        <StyledLink tabIndex={disabled ? -1 : 0}>Terms of Service</StyledLink>
      </Link>
      {` and `}
      <Link href={`/${UtilityPageSlugs.cookiePolicy}`} passHref>
        <StyledLink tabIndex={disabled ? -1 : 0}>Privacy Policy</StyledLink>
      </Link>
    </span>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`

const InputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.7rem;
`

const CheckboxWrap = styled.div`
  margin-bottom: 3rem;
`

const StyledLink = styled.a`
  color: var(--black);
  text-decoration: underline;
`

const AlertText = styled(Text)`
  color: var(--white);

  & + & {
    margin-top: 1.5rem;
  }
`
