import groq from 'groq'

export const UGC_CAROUSEL = groq`
  _key,
  _type,
  albumName,
  title,
  copy,
  link,
  label,
`
