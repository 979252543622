import groq from 'groq'
import styled from 'styled-components'
import { useState } from 'react'

import { Sanity, Shopify } from '@cellulargoods/types'
import {
  COLORS,
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { useDisableScroll, useFDAMessage } from '@cellulargoods/hooks'
import {
  Heading,
  SideSheet,
  Button,
  ButtonTypes,
  Bg,
  Text,
} from '@cellulargoods/core'

import { useCart } from 'hooks/useCart'
import { useSanity } from 'hooks/useSanity'

import { getObjFromEdges } from 'helpers/shopify'

import CartItem from 'components/Cart/CartItem'
import CartMetaContent from 'components/Cart/CartMetaContent'
import { ProductSuggestions } from 'components/Products/ProductSuggestions'

import { ALL_PRODUCTS_WITH_IMAGE } from 'queries/documents/products'
import { CART_PAGE } from 'queries/singletons/cart'

export interface SideSheetCartProps {
  isActive: boolean
  onCloseClick: () => void
}

const CART_DOCUMENT = groq`
  *[_type == "cart"][0]{
    ${CART_PAGE}
  }
`

export const CartSideSheet = ({
  isActive,
  onCloseClick,
}: SideSheetCartProps) => {
  const [checkingOut, setCheckingOut] = useState(false)
  /**
   * this is about checking whether the panel is open,
   * the hanlder is called on spring rest
   */
  const [isOpen, setIsOpen] = useState(false)
  const { cart, totalItems, proceedCheckout, eligibleOffers } = useCart()

  const offers = eligibleOffers()

  const [products] = useSanity<Sanity.Product[]>({
    query: ALL_PRODUCTS_WITH_IMAGE,
  })

  const [cartDocument] = useSanity<Sanity.Cart>({
    query: CART_DOCUMENT,
  })

  const [fda] = useFDAMessage()

  useDisableScroll(isActive)

  const lineItems = getObjFromEdges<Shopify.CheckoutLineItem>(cart?.lineItems)

  const bagIsEmpty = lineItems.length <= 0

  const handleClick = async () => {
    if (bagIsEmpty) {
      return
    } else {
      try {
        setCheckingOut(true)
        /**
         * this runs our tracking events and should fire
         * a callback that send the customer to the checkout
         * if it fails (because adblock) we redirect ourselves
         */
        const checkoutUrl = await proceedCheckout()

        if (checkoutUrl) {
          window.location.href = checkoutUrl
        } else {
          throw new Error('Failed to proceedCheckout')
        }
      } catch (err) {
        console.error(err)
        /**
         * If the above fails for whatever reason (async actions)
         * we just use the cart url and send them anyway.
         * The customer must be able to buy!
         */
        if (cart?.webUrl) {
          window.location.href = cart.webUrl
        }
      } finally {
        setCheckingOut(false)
      }
    }
  }

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  const handleProductClick = () => {
    onCloseClick()
  }

  return (
    <SideSheet
      isActive={isActive}
      onCloseClick={onCloseClick}
      onOpenChange={handleOpenChange}
    >
      <FlexWrap>
        <ContentWrap>
          <HeadingWrap>
            <div>
              <Heading fontStyle={FONT_STYLE_SOFIA_22_500}>
                {`Your bag (${totalItems})`}
              </Heading>
              {bagIsEmpty && (
                <EmptyBagText fontStyle={FONT_STYLE_SOFIA_16_400}>
                  {cartDocument?.copy ?? `Your bag is empty.`}
                </EmptyBagText>
              )}
            </div>
          </HeadingWrap>

          {cart && lineItems.length > 0 && (
            <Ul data-testid="cart-lineitems">
              {lineItems.map((item) => {
                const product = products?.filter(
                  (i) =>
                    i.product?.id?.toString() ===
                    item.variant?.product.id.split(
                      'gid://shopify/Product/'
                    )?.[1]
                )?.[0]
                return (
                  <CartItem key={item.id} {...item} image={product?.image} />
                )
              })}

              {offers?.map((i: any, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: COLORS.lightBrown,
                    padding: '1rem',
                    fontFamily: 'sofia-pro',
                    marginBottom: '1rem',
                  }}
                >
                  <h4 style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
                    {i?.title}
                  </h4>
                  <span style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
                    This will automatically apply on checkout.
                  </span>
                </div>
              ))}

              {offers?.length === 0 && cartDocument?.gwpMessage?.title ? (
                <div
                  style={{
                    backgroundColor: COLORS.lightGrey,
                    padding: '1rem',
                    fontFamily: 'sofia-pro',
                    marginBottom: '1rem',
                  }}
                >
                  <h4 style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
                    {cartDocument?.gwpMessage?.title}
                  </h4>
                  {cartDocument?.gwpMessage?.description ? (
                    <span style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
                      {cartDocument?.gwpMessage?.description}
                    </span>
                  ) : null}
                </div>
              ) : null}

              <FDAMessage>{fda}</FDAMessage>
            </Ul>
          )}

          {lineItems.length === 0 && cartDocument?.RelatedProducts && (
            <ProductSuggestions
              shouldRecalcPositions={isOpen}
              onClick={handleProductClick}
              items={cartDocument.RelatedProducts.relatedProducts}
            />
          )}
        </ContentWrap>

        {cart && (
          <CartBottom>
            <Gradient />
            <CartMetaContent subTotal={cart?.subtotalPriceV2?.amount} />
            <CheckoutButton
              variant={ButtonTypes.PRIMARY}
              bg={Bg.black}
              href={bagIsEmpty ? '/products' : undefined}
              onClick={handleClick}
              tag={bagIsEmpty ? 'a' : 'button'}
              disabled={checkingOut}
              testId="cart-submit"
            >
              {bagIsEmpty ? `Back to shop` : `Checkout`}
            </CheckoutButton>
            {cartDocument?.subtext ? (
              <CartSubtext fontStyle={FONT_STYLE_SOFIA_16_400}>
                {cartDocument.subtext}
              </CartSubtext>
            ) : null}
          </CartBottom>
        )}
      </FlexWrap>
    </SideSheet>
  )
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 2.9rem 0 2.2rem 0;
  margin-bottom: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 3.8rem 0 3.3rem 0;
    margin-bottom: 2.6rem;
  }
`

const EmptyBagText = styled(Text)`
  margin-top: 2rem;
`

const ContentWrap = styled.div`
  overflow: hidden;
  margin-bottom: 2rem;
`

const Ul = styled.ul`
  overflow-y: auto;
  height: 100%;
  padding-bottom: 100px;
`

const CartBottom = styled.div`
  position: relative;
`

const Gradient = styled.div`
  pointer-events: none;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: 100%;
  height: 6rem;
  width: 100%;
`

const CheckoutButton = styled(Button)`
  width: 100%;
`

const CartSubtext = styled(Text)`
  margin-top: 2rem;
  ${getFontStyles(FONT_STYLE_SOFIA_11_500)};
`

const FDAMessage = styled.div`
  color: #757575;
  border-top: solid 1px rgba(0, 0, 0, 0.2);

  padding-top: 3rem;
  line-height: 130%;

  padding-bottom: 6rem;
  ${getFontStyles(FONT_STYLE_SOFIA_11_500)};
`
