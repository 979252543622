import groq from 'groq'
import { MEDIA } from '../utils/media'
import { CTA_BUTTON } from '../utils/ctaButton'

export const GENERIC_CARD = groq`
  _key,
  _id,
  title,
  text,
  image[] {
    ${MEDIA}
  },
  CTAButton {
    ${CTA_BUTTON}
  }
`
