import groq from 'groq'
import { CTA_BUTTON } from '../utils/ctaButton'
import { MEDIA } from '../utils/media'

const COLUMN = groq`
  (_type == "MediaGridItem") => {
    _type,
    _key,
    asset[]{
      ${MEDIA}
    }
  },
  (_type == "TwoImageAndTextVerticalColumn") => {
    _key,
    _type,
    text,
    CTAButton {
      ${CTA_BUTTON}
    },
    media[] {
      ${MEDIA}
    }
  }
`

export const TWO_IMAGE_AND_TEXT = groq`
  _key,
  _type,
  leftCol_twoImageAndText[]{
    ${COLUMN}
  },
  rightCol_twoImageAndText[]{
    ${COLUMN}
  }
`
