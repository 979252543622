import groq from 'groq'
import { EXTERNAL_ARTICLE_CARD, INTERNAL_ARTICLE_CARD } from './articleCard'

export const ARTICLES_GRID = groq`
  _key,
  _type,
  title,
  text
`

export const ARTICLES = groq`
  (_type == 'internalArticle') => {
      ${INTERNAL_ARTICLE_CARD}
  },
  (_type == 'externalArticle') => {
      ${EXTERNAL_ARTICLE_CARD}
  },
`
