import groq from 'groq'
import { CTA_BUTTON } from '../utils/ctaButton'
import { IMAGE } from '../utils/image'

export const SPOTLIGHT = groq`
  _key,
  _type,
  subtitle,
  text,
  image {
    ${IMAGE}
  },
  CTAButton {
    ${CTA_BUTTON}
  }
`
