import groq from 'groq'

/**
 * TO DO – refactor to avoid nested dimension object
 */
export const IMAGE = groq`
    _key,
    _type,
   asset->{
    url,
    altText,
    metadata {
      dimensions {
        width,
        height
      }
    }
   }
`
