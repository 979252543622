import styled from 'styled-components'
import { useRouter } from 'next/router'
import { KeyboardEvent, useRef } from 'react'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  WIDTHS,
  MEDIA_QUERIES,
  getFontStyles,
  styleVisuallyHidden,
} from '@cellulargoods/styles'
import { useWindowResize } from '@cellulargoods/hooks'
import {
  Heading,
  Text,
  ButtonTypes,
  Button,
  CloseBtn,
  capitalizeFirstLetter,
} from '@cellulargoods/core'

import { AuthVariants } from 'references/constants'

import { ModalAuthLogin } from './ModalAuthLogin'
import { ModalAuthCreateAccount } from './ModalAuthCreateAccount'
import { ModalAuthForgotPassword } from './ModalAuthForgotPassword'
import { ModalAuthActivateAccount } from './ModalAuthActiveAccount'

export interface AuthModalProps {
  activeLink: AuthVariants
  bottomCtaLabel: string
  /**
   * BottomCtaHref
   *
   * Used when the AuthModal is used on a direct route such as `/login` or `/signup`
   *
   * The bottomCtaHref will be used to navigation to a different page.
   */
  bottomCtaHref?: string
  /**
   * onLinkClick
   *
   * Used when the AuthModal is used as a overlaying dialog box.
   *
   * onLinkClick will update the `activeLink` in a parent component to allow
   * the form to change.
   */
  onLinkClick?: (href?: AuthVariants | undefined) => void
  /**
   * onClose may only be present when showing the AuthModal as a modal overlay.
   */
  onClose?: () => void
  buttonLabel: string
  /**
   * shouldRedirectOnSubmit
   *
   * This will only happen on defined routes such as `/login` or `/signup`.
   */
  shouldRedirectOnSubmit: boolean

  isPage?: boolean
}

const AUTH_MODAL_STRINGS = {
  [AuthVariants.Forgot]: {
    title: 'Forgot your password',
    description:
      "Enter your details and we'll send you a link to reset your password.",
  },
  [AuthVariants.ActivateAccount]: {
    title: 'Activate your account',
    description: 'Enter your password to activate your account',
  },
}

const AuthModal = ({
  activeLink,
  bottomCtaHref,
  bottomCtaLabel,
  onClose,
  onLinkClick,
  shouldRedirectOnSubmit,
  isPage = false,
}: AuthModalProps) => {
  const tabRefs = useRef<Record<string, HTMLButtonElement>>({})
  const { width } = useWindowResize()

  const { push } = useRouter()

  const handleLinkClick = (href?: AuthVariants) => () => {
    /**
     * If onLinkClick is defined, we are showing the AuthModal as an actual modal,
     * So we want to use the NavigationAuthModal routing to re-render the form.
     */
    if (href !== activeLink && onLinkClick) {
      return onLinkClick(href)
    }

    // If there's no onLinkClick, we want to route to the href as we're on a defined page such as `/signup` or `/login`
    switch (href) {
      case AuthVariants.CreateAccount: {
        return push('/signup')
      }
      case AuthVariants.Login: {
        return push('/login')
      }
      case AuthVariants.Forgot:
        return push('/forgot')
      default:
        return
    }
  }

  const handleKeyDown =
    (mode: AuthVariants) => (e: KeyboardEvent<HTMLButtonElement>) => {
      const { code } = e

      if (code === 'ArrowLeft' || code === 'ArrowRight') {
        switch (mode) {
          case AuthVariants.CreateAccount: {
            if (onLinkClick) {
              tabRefs.current[AuthVariants.Login].focus()
              return onLinkClick(AuthVariants.Login)
            } else {
              return push('/login')
            }
          }
          case AuthVariants.Login: {
            if (onLinkClick) {
              tabRefs.current[AuthVariants.CreateAccount].focus()
              return onLinkClick(AuthVariants.CreateAccount)
            } else {
              return push('/signup')
            }
          }
          default:
            return
        }
      }
    }

  const handleForgotLinkClick = () => {
    if (activeLink !== AuthVariants.Forgot && onLinkClick) {
      return onLinkClick(AuthVariants.Forgot)
    } else {
      return push(`/${AuthVariants.Forgot}`)
    }
  }

  const handleCloseClick = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleSucessfulSubmit = () => {
    if (shouldRedirectOnSubmit) {
      push('/account')
    } else if (onClose) {
      onClose()
    }
  }

  const isCreateOrLogin =
    activeLink !== AuthVariants.Forgot &&
    activeLink !== AuthVariants.ActivateAccount

  return (
    <Wrap data-testid="auth-modal">
      {!isPage && (
        <ModalCloseButton
          onClick={handleCloseClick}
          size={width >= WIDTHS.desktop ? 24 : 32}
        />
      )}
      <ModalHeader>
        {!isCreateOrLogin ? (
          <>
            <Heading fontStyle={FONT_STYLE_SOFIA_22_500}>
              {AUTH_MODAL_STRINGS[activeLink].title}
            </Heading>
            <ForgotDescription fontStyle={FONT_STYLE_SOFIA_16_400}>
              {AUTH_MODAL_STRINGS[activeLink].description}
            </ForgotDescription>
          </>
        ) : (
          <TabList role="tablist" aria-label="Account form">
            {Object.values(AuthVariants).map((auth) =>
              auth !== AuthVariants.Forgot &&
              auth !== AuthVariants.ActivateAccount ? (
                <TabButton
                  key={auth}
                  ref={(ref) => (tabRefs.current[auth] = ref!)}
                  role="tab"
                  aria-selected={activeLink === auth}
                  onClick={handleLinkClick(auth)}
                  id={auth}
                  tabIndex={activeLink === auth ? 0 : -1}
                  onKeyDown={handleKeyDown(auth)}
                  aria-controls={`${auth}-tab`}
                >
                  {capitalizeFirstLetter(auth)}
                </TabButton>
              ) : null
            )}
          </TabList>
        )}
      </ModalHeader>
      <TabPanels $visible={isCreateOrLogin}>
        <TabPanel
          id={`${AuthVariants.Login}-tab`}
          aria-labelledby={AuthVariants.Login}
          aria-hidden={activeLink !== AuthVariants.Login}
          role="tabpanel"
        >
          <ModalAuthLogin
            visible={activeLink === AuthVariants.Login}
            onSubmitted={handleSucessfulSubmit}
            onForgotClick={handleForgotLinkClick}
          />
        </TabPanel>
        <TabPanel
          id={`${AuthVariants.CreateAccount}-tab`}
          aria-labelledby={AuthVariants.CreateAccount}
          aria-hidden={activeLink !== AuthVariants.CreateAccount}
          role="tabpanel"
        >
          <ModalAuthCreateAccount
            visible={activeLink === AuthVariants.CreateAccount}
            onSubmitted={handleSucessfulSubmit}
          />
        </TabPanel>
      </TabPanels>
      <TabPanel aria-hidden={activeLink !== AuthVariants.Forgot}>
        <ModalAuthForgotPassword visible={activeLink === AuthVariants.Forgot} />
      </TabPanel>
      <TabPanel
        data-testid="authmodal-activate"
        aria-hidden={activeLink !== AuthVariants.ActivateAccount}
      >
        <ModalAuthActivateAccount
          visible={activeLink === AuthVariants.ActivateAccount}
          onSubmitted={handleSucessfulSubmit}
        />
      </TabPanel>

      {bottomCtaHref ? (
        <BottomCta variant={ButtonTypes.TERTIARY} tag="a" href={bottomCtaHref}>
          {bottomCtaLabel}
        </BottomCta>
      ) : (
        <BottomCta variant={ButtonTypes.TERTIARY} onClick={handleLinkClick()}>
          {bottomCtaLabel}
        </BottomCta>
      )}
    </Wrap>
  )
}
export default AuthModal

const Wrap = styled.div`
  width: 100%;

  padding: 0 2rem 30px;
  background-color: white;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: 1;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 30px 4rem;
    width: 520px;
  }
`

const ModalCloseButton = styled(CloseBtn)`
  position: absolute;
  padding: 0.4rem;
  right: 1.6rem;
  top: 1.6rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0.8rem;
    right: 1.2rem;
    top: 1.2rem;
  }
`

const ModalHeader = styled.header`
  margin-top: 8rem;
  border-top: solid 1px var(--lightGrey);
  padding: 3rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
    border-top: none;
  }
`

const ForgotDescription = styled(Text)`
  margin: 2rem 0;
`

const TabList = styled.div``

const TabButton = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_22_500)};
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &[aria-selected='true'] {
    text-decoration: underline;
  }

  &[aria-selected='false'] {
    color: var(--accessibleGrey);
  }

  & + & {
    margin-left: 3rem;
  }
`

const TabPanels = styled.div<{ $visible: boolean }>`
  height: 100%;
  flex: 1;

  ${(props) => (!props.$visible ? styleVisuallyHidden : '')}
`

const TabPanel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  &[aria-hidden='true'] {
    ${styleVisuallyHidden}
  }
`

const BottomCta = styled(Button)`
  margin-top: 20px;
  text-decoration: none;
  display: block;
  text-align: center;
  align-self: center;
`
