import { IMAGE } from '../utils/image'
import groq from 'groq'

export const LOGOS_BANNER = groq`
  _type,
  _key,
  hasBackground,
  title,
  items[]{
    _key,
    label,
    type,
    url,
    "asset": asset.asset -> url,
    image{
      ${IMAGE}
    }
  }
`
