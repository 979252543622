import groq from 'groq'

export const MUX = groq`
    _key,
    _type,
    muxVideoFile {
        asset -> {
            playbackId,
            thumbTime,
        }
    },
    showControls,
    loopEnabled,
    muted,
    playsinline,
    autoplay,
`
