import groq from 'groq'
import { PAGE_META } from '../snippets/pageMeta'
import { REFERENCED_DOCS } from '../utils/refencedDocs'

export const SITE_SETTINGS = groq`
    *[_type == "SiteSettings" && !(_id in path("drafts.**"))] | order(_updatedAt desc)[0]{
        PageMeta {
            ${PAGE_META}
        },
        SiteBanner,
        usdExchangeRate,
        eurExchangeRate,
        fdaDisclaimer,
        ukOnlyMessage[],
        helpButton {
            chatEnabled,
            emailEnabled,
            searchEnabled,
            additionalLinks[]-> {
                ${REFERENCED_DOCS}
            }
        },
        "gwp": *[_type == "cart"][0].gwp[] {
            "offer": offertype,
            productQuantity,
            "qualifyingProducts": products[]->.product->.idEncoded,
            "autoaddProducts": autoaddProducts[]->.product->.idEncoded,
            spendThreshold,
        }
    }
`
