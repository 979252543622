import groq from 'groq'

export const IMAGE_SLIDER = groq`
  _key,
  _type,
  slider_start_point,
  left_image{
    asset -> {
      url
    }
  },
  right_image {
    asset -> {
      url
    }
  }
`
