import groq from 'groq'

export const REVIEW_CAROUSEL = groq`
  _key,
  _type,
  mode,
  title,
  copy,
  product->{
    title,
    product->{
      id,
    },
  },
  categories[] -> {
    slug {
      current,
    },
  },
  stars,
  count
`
