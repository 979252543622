import groq from 'groq'
import { IMAGE } from '../utils/image'

export const LOGOS_BANNER_WITH_QUOTE = groq`
  _key,
  _type,
  items[]{
    _key,
    asset {
      ${IMAGE}
    },
    quote {
      title,
      quote,
      author,
    }
  }
`
