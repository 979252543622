import groq from 'groq'

import { IMAGE, PRODUCT_FRAGMENT } from '@cellulargoods/services'
import { Sanity } from '@cellulargoods/types'

export type AllProductsWithImage = Array<{
  _id: string
  status: string
  product?: {
    id?: number
    idEncoded?: number
  }
  image: Sanity.MediaImage
}>

export const ALL_PRODUCTS_WITH_IMAGE = groq`
*[_type in ["product"]]{
  _id,
  status,
  product -> {
    id,
    idEncoded
  },
  category -> {
    slug {
      current
    }
  },
  image{
    ${IMAGE}
  }
}
`

export type AllProducts = Sanity.Product[]

export const ALL_PRODUCTS = groq`
*[_type == "product" && visibility != false]{
  ${PRODUCT_FRAGMENT}
}
`

export type AllProductsByCategory = Sanity.Product[]

export const ALL_PRODUCTS_BY_CATEGORY = groq`
  *[_type == "product" && visibility != false && category->slug.current == $slug]{
    ${PRODUCT_FRAGMENT}
  }
`
