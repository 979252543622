import { useEffect, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { Minus, Plus } from 'phosphor-react'

import { Text } from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export interface QuantitySelectorProps {
  /**
   * If active, it is possible to decrement to 0 which will
   * fire "onRemoved"
   */
  minusToRemove?: boolean
  onRemoved?: () => void
  onChangeQuantity?: (newValue: number) => void
  quantity?: number
  loading?: boolean
  className?: string
  showLegend?: boolean
  maxQuantityLimit?: number | null
  disabled?: boolean
}

export const QuantitySelector = ({
  minusToRemove,
  onRemoved,
  quantity = 1,
  maxQuantityLimit,
  onChangeQuantity,
  loading,
  className,
  showLegend,
  disabled,
}: QuantitySelectorProps) => {
  const [inputValue, setInputValue] = useState(quantity)
  const [buttonsDisabled, setButtonsDisabled] = useState({
    decrement: false,
    increment: false,
  })

  useEffect(() => {
    setInputValue(quantity)
  }, [quantity])

  const handleOnPlusClick = () => {
    if (loading) return

    if (onChangeQuantity) onChangeQuantity(quantity + 1)
  }

  const handleOnMinusClick = () => {
    if (loading) return

    if (quantity === 1 && onRemoved) {
      onRemoved()
      setButtonsDisabled((s) => ({
        ...s,
        decrement: true,
      }))
    } else {
      if (onChangeQuantity) onChangeQuantity(quantity - 1)
    }
  }

  useEffect(() => {
    if (maxQuantityLimit && quantity === maxQuantityLimit) {
      setButtonsDisabled((s) => ({
        ...s,
        increment: true,
      }))
    } else if (buttonsDisabled.increment) {
      setButtonsDisabled((s) => ({
        ...s,
        increment: false,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxQuantityLimit, quantity, buttonsDisabled.increment])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(parseInt(e.currentTarget.value))
  }

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.currentTarget.value)
    if (newQuantity === 0 && onRemoved) {
      onRemoved()
    } else if (onChangeQuantity) {
      onChangeQuantity(newQuantity)
    }
  }

  return (
    <Container className={className}>
      {showLegend && (
        <SelectorLabel tag="span" fontStyle={FONT_STYLE_SOFIA_11_400}>
          Quantity
        </SelectorLabel>
      )}
      <Selector
        className={clsx(loading && 'loading')}
        disabled={Boolean(disabled)}
      >
        <IconButton
          disabled={
            buttonsDisabled.decrement ||
            (!minusToRemove && quantity === 1) ||
            disabled
          }
          aria-label="Remove 1 item"
          data-testid="decrease-quantity"
          onClick={handleOnMinusClick}
        >
          <Minus size={20} />
        </IconButton>
        <TextContainer>
          <TextInput
            type="number"
            value={inputValue}
            onBlur={handleOnBlur}
            onChange={handleInputChange}
          />
        </TextContainer>
        <IconButton
          disabled={buttonsDisabled.increment || disabled}
          aria-label="Add 1 item"
          data-testid="increase-quantity"
          onClick={handleOnPlusClick}
        >
          <Plus size={20} />
        </IconButton>
      </Selector>
    </Container>
  )
}

const Container = styled.div``

const Selector = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: 1px solid var(--darkGrey1);
  align-items: stretch;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  overflow: hidden;

  &.loading {
    opacity: 0.2;
    pointer-events: none;
  }

  & > span {
    position: relative;
    bottom: 0.2rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    width: 21rem;
  }

  &:focus-within {
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const IconButton = styled.button<{
  disabled?: boolean
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  background: none;
  border: none;
  padding: 0;
  color: var(--black);
  line-height: 0;
  padding: 1.3rem 2rem 1.5rem 2rem;

  & > svg {
    color: var(--black);
  }

  @media (hover: hover) {
    &:hover {
      & > svg {
        color: var(--accessibleGrey);
      }
    }
  }
`

const SelectorLabel = styled(Text)`
  display: block;
  margin-bottom: 1rem;
`

const TextContainer = styled.div`
  flex: 1;
`

const TextInput = styled.input`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  display: block;
  padding: 0;
  border: none;
  background: none;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0.2rem;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }
`
