import groq from 'groq'

import { Sanity, Maybe } from '@cellulargoods/types'
import { createSanityClientRead, uniqueOnly } from '@cellulargoods/core'

import { ARTICLES } from '../../queries/components/articleGrid'

export type MutatedCMSComponents = Array<
  | Maybe<Omit<Sanity.CMSComponentTypes, 'Sanity_ArticlesGrid'>>
  | Maybe<Sanity.MutatedSanityArticlesGrid>
>

export type MutatedDocument = {
  CMSComponents?: Maybe<MutatedCMSComponents>
  ArticlesGrid?: Maybe<Sanity.MutatedSanityArticlesGrid>
}

export const mergeArticlesWithArticlesGrid = async <
  TDoc extends Sanity.Document
>(
  document: TDoc,
  preview?: boolean
) => {
  const { ArticlesGrid, CMSComponents } = document

  /**
   * Do we even need to do this?
   */
  const doesArticlesGridExist =
    Boolean(ArticlesGrid) ||
    Boolean(
      Array.isArray(CMSComponents) &&
        CMSComponents.filter((comp) => comp?._type === 'ArticlesGrid')
    )

  if (doesArticlesGridExist) {
    /**
     * We need to get our articles
     */
    const query = groq`
      *[_type in ["internalArticle", "externalArticle"]] | order(date desc)[]{
        ${ARTICLES}
      }
    `

    /**
     * TODO – add type safety
     */
    const articles = await createSanityClientRead(preview).fetch<any[]>(query)

    const categories = articles
      .map(
        (article) =>
          (article && Array.isArray(article.Tags) && article.Tags[0]?.title) ||
          ''
      )
      .filter(uniqueOnly)

    if (Boolean(ArticlesGrid)) {
      /**
       * This only applies to `learn/articles/all`
       */
      return {
        ...document,
        ArticlesGrid: {
          ...ArticlesGrid,
          articles,
          categories,
        },
      }
    } else {
      /**
       * If we're using it as part of CMSComponents
       * then push it into the object
       */
      return {
        ...document,
        CMSComponents: CMSComponents?.map((comp) =>
          comp?._type === 'ArticlesGrid'
            ? {
                ...comp,
                articles,
                categories,
              }
            : comp
        ),
      }
    }
  } else {
    return document
  }
}
