import styled from 'styled-components'
import Link from 'next/link'

import { createSlug, Heading, Media, SlugType } from '@cellulargoods/core'
import { Sanity } from '@cellulargoods/types'
import { aspectRatio, FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'

type ProductSuggestionCardProps = Sanity.Product & {
  className?: string
  onClick?: () => void
}

export const ProductSuggestionCard = ({
  image,
  title,
  category,
  slug,
  className,
  onClick,
}: ProductSuggestionCardProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return (
    <Link
      passHref
      href={createSlug(SlugType.FUNCTIONAL_PDP, [
        category?.slug?.current ?? '',
        slug?.current,
      ])}
    >
      <SuggestionCard onClick={handleClick} className={className}>
        <SuggestionMedia>
          {image ? <Media {...image} sizes={['33vw']} /> : null}
        </SuggestionMedia>
        <SuggestionName tag="h2" fontStyle={FONT_STYLE_SOFIA_11_400}>
          {title}
        </SuggestionName>
      </SuggestionCard>
    </Link>
  )
}

const SuggestionCard = styled.a`
  max-width: 12rem;
  display: block;
  width: 100%;
  flex: 1 0 12rem;
  text-decoration: none;
`

const SuggestionMedia = styled.div`
  ${aspectRatio(120, 170, false)}
  background-color: var(--softGrey);
`

const SuggestionName = styled(Heading)`
  margin-top: 2rem;
`
