import styled from 'styled-components'
import { Hit } from '@algolia/client-search'
import Link from 'next/link'

import { Image, Text } from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_500,
} from '@cellulargoods/styles'

export type SearchHitProps = Hit<any>

export const SearchHit = ({
  title,
  imageUrl,
  indexLabel,
  objectID,
  url,
  handleClose,
}: SearchHitProps) => {
  const renderImage = () => {
    if (imageUrl) {
      return (
        <ImageWrap>
          <Image
            image={{
              _type: 'MediaImage',
              asset: {
                url: imageUrl,
              },
            }}
            layout="fill"
            objectFit="cover"
            sizes={['12vw']}
          />
        </ImageWrap>
      )
    } else {
      return <ImagePlaceholder />
    }
  }

  if (!url) {
    console.error('Missing url in SearchHit')
    return null
  }

  return (
    <Li key={objectID}>
      <Link href={url} passHref>
        <Wrap onClick={handleClose}>
          {renderImage()}
          <ContentWrap>
            {indexLabel && (
              <Text fontStyle={FONT_STYLE_SOFIA_11_400}>
                {indexLabel.toUpperCase()}
              </Text>
            )}
            <Text fontStyle={FONT_STYLE_SOFIA_16_500}>{title}</Text>
          </ContentWrap>
        </Wrap>
      </Link>
    </Li>
  )
}

const Li = styled.li`
  list-style-type: none;
  & + & {
    margin-top: 20px;
  }
`

const Wrap = styled.a`
  display: flex;
  text-decoration: none;
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  justify-content: center;
`

const ImageWrap = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  background: var(--lightGrey);
  overflow: hidden;
`

const ImagePlaceholder = styled(ImageWrap)`
  background-image: url('/images/FAQ.png');
  background-color: var(--lightGrey);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 100%;
`
