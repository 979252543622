import { useEffect, useState } from 'react'

import { createSanityClientRead } from '@cellulargoods/core'

type UseSanityArgs = {
  query: string
  params?: Record<string, unknown>
  enabled?: boolean
}

type UseSanity = <TResponse>(
  args: UseSanityArgs
) => [data: TResponse | undefined, loading: boolean, error: Error | undefined]

export const useSanity: UseSanity = <TResponse>({
  query,
  params = {},
  enabled = true,
}: UseSanityArgs) => {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TResponse>()

  useEffect(() => {
    if (!enabled || data || error) {
      return () => {
        /**
         * intentional no-op
         */
      }
    }

    setLoading(true)

    const client = createSanityClientRead()

    client
      .fetch<TResponse>(query, params)
      .then(setData)
      .catch((err) => {
        console.error(err)
        setError(err)
      })
      .finally(() => setLoading(false))
  }, [query, enabled, params, data, error])

  return [data, loading, error]
}
