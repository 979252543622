import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import groq from 'groq'

import { Yotpo, NonNullSkipArray, PickType, Sanity } from '@cellulargoods/types'
import {
  createSanityClientRead,
  ensureLinkHasProtocol,
} from '@cellulargoods/core'

import { IMAGE } from '../queries/utils/image'

const YOTPO_APP_KEY = process.env.YOTPO_APP_KEY

const yotpoApiRequest = axios.create({
  baseURL: 'https://api.yotpo.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const yotpoCDNApiRequest = axios.create({
  baseURL: 'https://api-cdn.yotpo.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// type ProductDataReturnType =
//   | NonNullSkipArray<
//       PickType<Yotpo.GetAlbumByNameImageManual, 'tagged_products'>
//     >
//   | PickType<Yotpo.GetAlbumByNameImageReview, 'product'>
//   | null

export class YotpoApi {
  /**
   * Here we're getting an album of photos from Yotpo.
   * We then trim down the payload to only include the information we want to know
   */
  getPhotoAlbum = async (albumName: string): Promise<Yotpo.PhotoAlbum> => {
    try {
      const url = `/v1/widget/${YOTPO_APP_KEY}/albums/by_name?album_name=${albumName}`
      const { data } = await yotpoApiRequest.get<Yotpo.GetAlbumByNameResponse>(
        url
      )

      if (
        data.status.code === 200 &&
        data.response.images &&
        Array.isArray(data.response.images)
      ) {
        const payload = data.response.images
          .map((obj) => ({
            source: obj.source,
            imageId: obj.image_id,
            lowResImage: ensureLinkHasProtocol(obj.low_resolution_image_url),
            imageUrl: ensureLinkHasProtocol(obj.original_image_url),
            username:
              (obj.source === 'review'
                ? obj.review?.user.display_name
                : obj.post?.username) ?? null,
            profilePhoto:
              obj.source === 'instagram' ? obj.post.profile_picture : null,
            content:
              obj.source === 'instagram'
                ? obj.post.content
                : obj.source === 'review'
                ? obj.review.content
                : null,
            stars: obj.source === 'review' ? obj.review.score : null,
            product: this.getProductData(obj),
          }))
          .filter((obj) => obj.imageUrl && obj.lowResImage)

        /**
         * Typescript doesn't see that through filtering the array we're making sure
         * the string keys actually exist so we're not passing objects with null value
         */
        return payload as Yotpo.PhotoAlbum
      } else {
        throw new Error(
          `no images returned from Yotpo for the album ${albumName}`
        )
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      return []
    }
  }

  getProductReviews = async (
    productId: number,
    options: Yotpo.GetReviewOptions
  ): Promise<Yotpo.ProductReviews> => {
    try {
      const { stars, pageNumber } = options
      const url = `/v1/widget/${YOTPO_APP_KEY}/products/${productId}/reviews.json?per_page=150&page=${
        pageNumber ?? 1
      }`
      const { data } = await yotpoCDNApiRequest.get<Yotpo.GetProductReviews>(
        url
      )

      if (
        data.status.code === 200 &&
        data.response.reviews &&
        Array.isArray(data.response.reviews)
      ) {
        let product = data.response.products[0]

        if (product) {
          const client = createSanityClientRead()

          const image = await client.fetch<Sanity.MediaImage>(
            groq`
            *[_type == "product" && product->id == $id][0].image {
              ${IMAGE}
            }
          `,
            {
              id: Number(product.domain_key),
            }
          )

          if (image) {
            product = {
              ...product,
              image,
            }
          }
        }

        return {
          score: data.response.bottomline.average_score,
          totalReviews: data.response.bottomline.total_review,
          product: product ?? null,
          reviews: data.response.reviews
            .filter((review) => review.score >= stars)
            .map((review) => ({
              id: review.id,
              score: review.score,
              content: review.content.replace(/&#x27;/g, "'"),
              title: review.title.replace(/&#x27;/g, "'"),
              createdAt: review.created_at,
              customFields: review.custom_fields,
              user: {
                name: review.user.display_name,
                image: review.user.social_image ?? null,
              },
            })),
        }
      } else {
        throw new Error(
          `no reviews returned from Yotpo for the product with id ${productId}`
        )
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      /**
       * this won't render anything on the front end
       */
      return {
        product: null,
        score: 0,
        totalReviews: 0,
        reviews: [],
      }
    }
  }

  getCategoryReviews = async (
    productIds: number[],
    options: Yotpo.GetReviewOptions
  ): Promise<Yotpo.CategoryReviews> => {
    try {
      const reviewsPerProduct = await Promise.all(
        productIds.map(async (id) => await this.getProductReviews(id, options))
      )

      const reviews = reviewsPerProduct.flatMap(({ reviews, product }) =>
        reviews.map((review) => ({
          ...review,
          product,
        }))
      )

      const score = reviews.reduce((acc, curr) => {
        return acc + curr.score
      }, 0)

      return {
        reviews,
        totalReviews: reviews.length,
        score: score / reviews.length,
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      return {
        totalReviews: 0,
        score: 0,
        reviews: [],
      }
    }
  }

  getSiteReviews = async (): Promise<Yotpo.SiteReviews> => {
    try {
      const url = `/v1/widget/${YOTPO_APP_KEY}/products/yotpo_site_reviews/reviews.json?per_page=150&page=1`
      const { data } = await yotpoCDNApiRequest.get<Yotpo.GetSiteReviews>(url)

      if (
        data.status.code === 200 &&
        data.response.reviews &&
        Array.isArray(data.response.reviews)
      ) {
        return {
          score: data.response.bottomline.average_score,
          totalReviews: data.response.bottomline.total_review,
          reviews: data.response.reviews.map((review) => ({
            id: review.id,
            score: review.score,
            content: review.content.replace(/&#x27;/g, "'"),
            title: review.title.replace(/&#x27;/g, "'"),
            createdAt: review.created_at,
            customFields: review.custom_fields,
            user: {
              name: review.user.display_name,
              image: review.user.social_image ?? null,
            },
          })),
        }
      } else {
        throw new Error(`no reviews returned from Yotpo for the site`)
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      /**
       * this won't render anything on the front end
       */
      return {
        score: 0,
        totalReviews: 0,
        reviews: [],
      }
    }
  }

  getBottomLineScore = async (
    productId: number
  ): Promise<Yotpo.ProdBottomLine> => {
    const url = `/v1/widget/${YOTPO_APP_KEY}/products/${productId}/reviews.json`
    const { data } = await yotpoCDNApiRequest.get<Yotpo.GetProductReviews>(url)

    try {
      if (data.status.code === 200) {
        return {
          score: data.response.bottomline.average_score,
          totalReviews: data.response.bottomline.total_review,
        }
      } else {
        throw new Error(
          `Product bottomline not found by Yotpo with id ${productId}`
        )
      }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)

      return {
        score: 0,
        totalReviews: 0,
      }
    }
  }

  postReviewVote = async (
    reviewId: string,
    voteType: 'up' | 'down'
  ): Promise<void> => {
    try {
      const url = `/reviews/${reviewId}/vote/${voteType}`

      await yotpoApiRequest({
        method: 'POST',
        url,
      })

      return
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
  }

  getReviewSearchResults = async ({
    searchTerm,
    productIdentifier,
  }: {
    searchTerm: string
    productIdentifier: string
  }): Promise<Yotpo.SearchReviewsResults> => {
    try {
      if (!searchTerm || !productIdentifier) {
        throw new Error('No search term or product identifier provided')
      }
      const url = `/v1/reviews/${YOTPO_APP_KEY}/filter.json`
      const { data } = await yotpoCDNApiRequest.post(url, {
        domain_key: productIdentifier,
        free_text_search: searchTerm,
      })
      return data.response.reviews
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      throw err
    }
  }

  private getProductData = (
    obj: Yotpo.GetAlbumByNameImage
  ):
    | NonNullSkipArray<
        PickType<Yotpo.GetAlbumByNameImageManual, 'tagged_products'>
      >
    | PickType<Yotpo.GetAlbumByNameImageReview, 'product'>
    | null => {
    switch (obj.source) {
      case 'instagram': {
        const [firstProd] = obj.tagged_products
        return firstProd ?? null
      }
      case 'manual_upload':
        const [firstProd] = obj.tagged_products
        return firstProd ?? null
      case 'review':
        return obj.product
    }
  }
}
