import algoliasearch from 'algoliasearch'
import { useEffect, useState } from 'react'
import { Hit } from '@algolia/client-search'

import combineAllHits from 'helpers/combineAllHits'

import { AlgoliaIndexes } from 'references/constants'

import config from 'references/config'

const searchClient = algoliasearch(
  config.algoliaApplicationId,
  config.algoliaSearchApiKey
)

interface Options {
  searchOnEmptyValue: boolean
}

const defaultOptions: Options = {
  searchOnEmptyValue: true,
}

export const useAlgoliaSearch = (search: string, options = defaultOptions) => {
  const [hits, setHits] = useState<Hit<any>[]>([])

  useEffect(() => {
    if (!options.searchOnEmptyValue && search.length === 0) return

    searchClient
      .multipleQueries([
        {
          indexName: AlgoliaIndexes.products,
          query: search,
        },
        {
          indexName: AlgoliaIndexes.learn,
          query: search,
        },
        {
          indexName: AlgoliaIndexes.articles,
          query: search,
        },
        {
          indexName: AlgoliaIndexes.faq,
          query: search,
        },
      ])
      .then(({ results }) => {
        const allHits: Hit<any>[] = combineAllHits(results)
        setHits(allHits)
      })
      .catch((err) => {
        console.error(err)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (!options.searchOnEmptyValue && search.length === 0 && hits.length > 0) {
      setHits([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return hits
}
