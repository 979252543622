import groq from 'groq'

export const REFERENCED_DOCS = groq`
    "_key": _id,
    _type,
    title,
    "slug": slug.current,
    (_type == "functionalProductDisplayPage" || _type == "enhancedProductDisplayPage") => {
        "title": product->title,
        "slug": product->slug.current,
        "category": product->category->slug.current,
    },
    (_type == "internalArticle" || _type == "externalArticle") => {
        "tag": Tags[0]->slug.current,
    },
`
