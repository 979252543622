import { SearchResponse } from '@algolia/client-search'

/**
 * Takes an array of algolia Index SearchResponse e.g
 * ```
 * [
 *  {
 *    hits: [...hits]
 *    indexName: 'products'
 *  },
 *  {
 *   hits: [...hits],
 *   indexName: 'learn'
 *  }
 * ]
 * ```
 *
 * and makes them flat:
 *
 * ```
 * [
 *   {
 *     title: 'Face-oil',
 *     index: 'products'
 *   },
 *   {
 *     title: 'Cannabinoids',
 *     index: 'learn'
 *   }
 * ]
 * ```
 */
const combineAllHits = (results: Array<SearchResponse<any>>) => {
  return results.reduce((a: any, b: SearchResponse) => {
    return [...a, ...b.hits]
  }, [])
}

export default combineAllHits
