import groq from 'groq'

export const YOUTUBE = groq`
    _key,
    _type,
    url,
    showControls,
    loopEnabled,
    playsinline,
    autoplay,
`
