import groq from 'groq'

export const BENEFITS_BANNER = groq`
  _key,
  _type,
  hasBackground,
  benefitsBannerItems[]{
    _key,
    _type,
    title,
    text,
    icon {
      _type,
      asset->{
        url
      }
    }
  }
`
