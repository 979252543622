const config = {
  // Frontend Apollo
  apolloClientGraphQLEndpoint: '/api/graphql',

  /**
   * Algolia
   */
  algoliaApplicationId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
  algoliaSearchApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY!, // Public API Key.

  /**
   * Used for Shopify Admin API
   */

  /**
   * Used for gorgias ticket creation
   */
  gorgiasUsername: process.env.GORGIAS_USERNAME,
  gorgiasPassword: process.env.GORGIAS_API_KEY,

  /**
   * Yotpo
   */
  yotpoAppKey: process.env.YOTPO_APP_KEY,
}

export default config
