import groq from 'groq'

export const REVIEW_MODULE = groq`
  _key,
  _type,
  mode,
  stars,
  count,
  product->{
    title,
    product->{
      id,
    },
  },
  categories[]->{
    slug {
      current,
    },
  },
`
