import groq from 'groq'

import { IMAGE } from '../utils/image'
import { MUX } from '../utils/mux'

import { HEADER_TEXT } from './headerText'
import { FEATURE_CARD } from './featureCard'

const BULLET_LIST = groq`
  _key,
  _type,
  title,
  items[]{
    title,
    text,
    icon{
      asset->{
        url
      }
    }
  }
`

const FULL_LEFT_RIGHT_COLUMN = groq`
    (_type == 'MediaImage') => {
        ${IMAGE}
    },
    (_type == 'MediaMux') => {
        ${MUX}
    },
    (_type == 'HeaderText') => {
        ${HEADER_TEXT}
    },
    (_type == 'FeatureCard') => {
        ${FEATURE_CARD}
    },
    (_type == 'BulletList') => {
        ${BULLET_LIST}
    },
`

export const FULL_LEFT_RIGHT = groq`
  _key,
  _type,
  hasBackground,
  hasMargins,
  rightCol[]{
    ${FULL_LEFT_RIGHT_COLUMN}
  },
  leftCol[]{
    ${FULL_LEFT_RIGHT_COLUMN}
  }
`
