import { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import {
  Text,
  Bg,
  Button,
  ButtonTypes,
  Input,
  getQueries,
  validateValues,
} from '@cellulargoods/core'
import { FONT_STYLE_SOFIA_14_400, getFontStyles } from '@cellulargoods/styles'

import Alert from 'components/Cards/Alert'

import { useStore } from 'store'

import { testPassword } from 'helpers/accounts'

import { STRINGS } from 'references/locale'

const ACTIVATE_BASE_STATE = {
  values: {
    password: '',
  },
  errors: {
    password: '',
  },
  success: false,
  loading: false,
  error: '',
}

type ForgotBaseState = typeof ACTIVATE_BASE_STATE

type ModalAuthActivateAccountProps = {
  visible?: boolean
  onSubmitted?: () => void
}

export const ModalAuthActivateAccount = ({
  visible,
  onSubmitted,
}: ModalAuthActivateAccountProps) => {
  const [{ values, errors, loading, success, error }, setFormState] =
    useState(ACTIVATE_BASE_STATE)

  const router = useRouter()

  const queries = getQueries(router.asPath) as {
    url?: string
  }

  const activateCustomer = useStore((state) => state.activateCustomer)

  useEffect(() => {
    if (!visible) {
      setFormState(ACTIVATE_BASE_STATE)
    }
  }, [visible])

  const handleInputChange =
    (fieldName: keyof ForgotBaseState['values']) =>
    (value: ForgotBaseState['values'][typeof fieldName]) => {
      setFormState((s) => ({
        ...s,
        values: {
          ...s.values,
          [fieldName]: value,
        },
      }))
    }

  const handleSubmit = async (e: FormEvent) => {
    const { password } = values

    e.preventDefault()

    if (!queries.url) {
      return
    }

    setFormState((s) => ({
      ...s,
      loading: true,
      success: false,
      submittedEmail: '',
      errors: ACTIVATE_BASE_STATE.errors,
    }))

    const { valid } = validateValues(
      {
        password,
      },
      (_, value) => testPassword(value)
    )
    if (!valid) {
      setFormState((s) => ({
        ...s,
        loading: false,
        errors: {
          password: STRINGS['activate-account.form.error.password'],
        },
      }))
      return
    } else {
      try {
        const { success, error, customerErrors } = await activateCustomer({
          activationUrl: queries.url,
          password,
        })

        if (!success && customerErrors) {
          setFormState((s) => ({
            ...s,
            loading: false,
            errors: {
              ...s.errors,
              ...customerErrors,
            },
          }))
        } else if (!success && error) {
          setFormState((s) => ({
            ...s,
            loading: false,
            error,
          }))
        } else {
          setFormState((s) => ({
            ...s,
            loading: false,
            success,
          }))

          if (onSubmitted) {
            onSubmitted()
          }
        }
      } catch (err) {
        console.error(err)
        setFormState((s) => ({
          ...s,
          loading: false,
          error: JSON.stringify(err),
        }))
      }
    }
  }

  return (
    <>
      {success && (
        <Alert intent="success">
          <AlertText>{`You've successfully actived your account. You can now login.`}</AlertText>
        </Alert>
      )}
      {error && (
        <Alert intent="fail">
          <AlertText>{error}</AlertText>
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Input
          disabled={!visible || loading}
          type="password"
          value={values.password}
          onChange={handleInputChange('password')}
          error={errors.password}
          label="Your password"
        />
        <Button
          type="submit"
          variant={ButtonTypes.PRIMARY}
          isFullWidth
          bg={Bg.black}
          disabled={!visible || loading || values.password === ''}
        >
          Activate Account
        </Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`

const AlertText = styled(Text)`
  ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
  color: var(--white);

  & + & {
    margin-top: 1.5rem;
  }
`
