import groq from 'groq'
import omitBy from 'lodash.omitby'
import { createSanityClientRead, isNull } from '@cellulargoods/core'
import { Sanity } from '@cellulargoods/types'
import { LAYOUT } from '../queries/layout'

import { UTILITY_NAVIGATION } from 'queries/documents/utilityPage'

import { transformDocument, TransformedDocument } from './transformDocument'

export type FetchDocumentParams = {
  filter?: string
  projection?: string
  preview?: boolean
  params?: Record<string, unknown>
}

type FetchedDocument<TFetched> = Sanity.GetLayoutDataPayload & {
  document?: TFetched
}

type Document = Sanity.Document & {
  OneTileProductPromo?: Sanity.OneTileProductPromo
}

type FetchedResult<TFetchedDocument> = Sanity.GetLayoutDataPayload & {
  document: TransformedDocument<TFetchedDocument> | null
  utilityNavigation: Sanity.UtilityNavigation | null
}

export const fetchDocument = async <TFetchedDocument extends Document>({
  filter,
  projection,
  preview,
  params = {},
}: FetchDocumentParams): Promise<FetchedResult<TFetchedDocument> | null> => {
  try {
    const documentProjection = projection ? `{${projection}}` : ''
    const documentQuery = filter
      ? `"document": *[${filter}] | order(_updatedAt desc)[0] ${documentProjection}`
      : ''

    const pageQuery = groq`
    {
        ${LAYOUT}
        ${documentQuery}
       
    }
  `

    // DEBUG QUERY
    // console.log(pageQuery.replace(/(\r\n|\n|\r)/gm, ''))

    const client = createSanityClientRead(preview)

    const { document, ...restResult } = await client.fetch<
      FetchedDocument<TFetchedDocument>
    >(pageQuery, params)

    let doc = document

    /**
     * Merge in OneTileProductPromo if there so it's used
     * in the CMS renderer
     */
    if (
      document &&
      document._type == 'functionalProductDisplayPage' &&
      document?.OneTileProductPromo
    ) {
      doc = {
        ...document,
        CMSComponents: [
          {
            ...document.OneTileProductPromo,
            hasReviewsModule:
              (document?.CMSComponents?.findIndex(
                (module) => module?._type === 'ReviewsModule'
              ) ?? -1) >= 0,
          },
          ...(document.CMSComponents ?? []),
        ],
      }
    }

    const mutatedDocument = await transformDocument(doc, preview)

    if (mutatedDocument?.PageMeta) {
      mutatedDocument.PageMeta = omitBy(
        (document ?? {}).PageMeta,
        isNull
      ) as Sanity.PageMeta
    }

    let utilityNavigation: Sanity.UtilityNavigation | null = null

    /**
     * Attach the utilityNavigation if it's a utility page
     */
    if (document && document._type === 'utilityPage') {
      utilityNavigation = await client.fetch<Sanity.UtilityNavigation>(
        UTILITY_NAVIGATION
      )
    }

    if (!document && documentQuery) {
      console.warn(
        `No document found with sanity query for filter – ${filter} with params – ${JSON.stringify(
          params
        )}, this could be intentional depending on the template.`
      )
      return null
    }

    return {
      document: mutatedDocument,
      utilityNavigation,
      ...restResult,
    }
  } catch (err) {
    console.error(err)
    return null
  }
}
