import groq from 'groq'

export const LINK = groq`
  linkType,
  label,
  (linkType == 'internal' && linkInternalString != null) => {
    "link": linkInternalString
  },
  (linkType == 'internal' && linkInternal != null) => {
    "referenceType": linkInternal->_type,
    "link": linkInternal->slug.current,
    (linkInternal->_type == "functionalProductDisplayPage" || linkInternal->_type == "enhancedProductDisplayPage") => {
      "link": linkInternal->product->slug.current,
      "category": linkInternal->product->category->slug.current,
    },
    (linkInternal->_type == 'internalArticle') => {
      "category": linkInternal->Tags[0]->slug.current,
    },
  },
  (linkType == 'external') => {
    "link": linkExternal,
  },
`
