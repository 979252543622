import groq from 'groq'
import { CTA_BUTTON } from '../utils/ctaButton'

export const HEADER_TEXT = groq`
  _key,
  _type,
  title,
  subtitle,
  body,
  size,
  centerOnMobile,
  CTAButton {
    ${CTA_BUTTON}
  }
`
