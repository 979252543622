import groq from 'groq'
import { MEDIA } from '../utils/media'

export const RELATED_LINKS = groq`
  _key,
  _type, 
  title,
  items[]{
    _key,
    _type,
    label,
    media[]{
      ${MEDIA}
    },
    link,
    reference->{
      _type,
      slug {
        current
      }
    }
  }
`
