import { Sanity } from '@cellulargoods/types'

import { mergeArticlesWithArticlesGrid } from './cms/mergeArticlesWithArticlesGrid'
import { mergeYotpoData } from './cms/mergeYotpoData'

export type TransformedDocument<TDoc extends Sanity.Document> = TDoc & {
  product: Sanity.Product | Sanity.ProductWithYotpo | null
}

export const transformDocument = async <TDoc extends Sanity.Document>(
  document?: TDoc,
  preview?: boolean
): Promise<TransformedDocument<TDoc> | null> => {
  if (!document) {
    return null
  }

  const documentWithArticles = await mergeArticlesWithArticlesGrid(
    document,
    preview
  )

  const documentWithYotpoData = await mergeYotpoData(
    documentWithArticles,
    preview
  )

  return documentWithYotpoData
}
