import groq from 'groq'

export const COMPARISON_TABLE = groq`
  _key,
  _type,
  title,
  text,
  additionText,
  comparisonTable {
    _key,
    rows[] {
      _key,
      cells
    }
  }
`
