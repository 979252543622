import FocusLock from 'react-focus-lock'
import { useEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'

import {
  useKeyPress,
  useDisableScroll,
  useWindowResize,
} from '@cellulargoods/hooks'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

import AuthModal from 'components/Modals/ModalAuth'

import { AuthVariants } from 'references/constants'

interface Props {
  isActive: boolean
  onClose: () => void
}
const NavigationAuthModal = ({ isActive, onClose }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  const handleEscape = () => {
    if (isActive) onClose()
  }

  useKeyPress('Escape', handleEscape)

  const [activeLink, setActiveLink] = useState<AuthVariants>(AuthVariants.Login)

  const { height, width } = useWindowResize()

  /**
   * When closed, reset the activeLink to show the Login form.
   */
  useEffect(() => {
    if (!isActive && activeLink !== AuthVariants.Login) {
      setActiveLink(AuthVariants.Login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  useDisableScroll(isActive)

  const handleLinkClick = (href?: AuthVariants | undefined) => {
    if (href) {
      setActiveLink(href)
      return
    }

    switch (activeLink) {
      case AuthVariants.Login: {
        return setActiveLink(AuthVariants.CreateAccount)
      }
      case AuthVariants.Forgot:
      case AuthVariants.CreateAccount: {
        return setActiveLink(AuthVariants.Login)
      }
    }
  }

  const { opacity } = useSpring({
    opacity: isActive ? 1 : 0,
    onRest: () => {
      const { current: dialog } = dialogRef

      if (dialog && !isActive) {
        dialog.style.display = 'none'
      }
    },
    onStart: () => {
      const { current: dialog } = dialogRef

      if (dialog && isActive) {
        dialog.style.display = 'flex'
      }
    },
  })

  return (
    <Wrap
      open={isActive}
      ref={dialogRef}
      style={{
        opacity,
        height: `${height}px`,
        width: `${width}px`,
      }}
      aria-hidden={!isActive}
    >
      <FocusLock disabled={!isActive} className="focus">
        <AuthModal
          activeLink={activeLink}
          bottomCtaLabel={bottomCtaLabels[activeLink]}
          buttonLabel={buttonLabels[activeLink]}
          onLinkClick={handleLinkClick}
          onClose={onClose}
          shouldRedirectOnSubmit
        />
      </FocusLock>
    </Wrap>
  )
}

export default NavigationAuthModal

export const bottomCtaLabels = {
  [AuthVariants.CreateAccount]: 'Do you already have a Cellular Goods account?',
  [AuthVariants.Login]: 'Don’t have an account yet?',
  [AuthVariants.ActivateAccount]: 'Don’t have an account yet?',
  [AuthVariants.Forgot]: 'Back to Login',
}

export const buttonLabels = {
  [AuthVariants.CreateAccount]: 'Create account',
  [AuthVariants.Login]: 'Login',
  [AuthVariants.ActivateAccount]: 'Activate account',
  [AuthVariants.Forgot]: 'Reset password',
}

const Wrap = styled(animated.dialog)`
  position: fixed;
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  background: rgba(0, 0, 0, 0.3);

  .focus {
    height: 100%;
    width: 100%;
  }

  ${MEDIA_QUERIES.desktopUp} {
    & .focus {
      height: 85%;
      max-height: 700px;
      width: auto;
    }
  }
`
