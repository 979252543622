import { RELATED_PRODUCTS } from '@cellulargoods/services'
import groq from 'groq'

export const CART_PAGE = groq`
    RelatedProducts { 
        ${RELATED_PRODUCTS}
    },
    copy,
    subtext,
    gwpMessage {
        title,
        description
    }
`
