import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from '@react-spring/web'

import {
  FONT_STYLE_SOFIA_14_400,
  FONT_STYLE_SOFIA_16_400,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { useClickOutside } from '@cellulargoods/hooks'
import { Sanity } from '@cellulargoods/types'

import { useStore } from 'store'
import { makeUrlFromType } from '@cellulargoods/core/src/helpers/links'

interface GorgiasChat {
  close: () => void
  open: () => void
  init: () => Promise<GorgiasChat>
  isOpen: () => boolean
  hidePoweredBy: (bool: boolean) => void
  on: (eventName: string, handler: () => void) => void
}

declare global {
  interface Window {
    GorgiasChat?: GorgiasChat
  }
}

interface Props extends Sanity.HelpButton {
  onSearchClick: () => void
}

export const WidgetHelp = ({
  onSearchClick,
  chatEnabled,
  emailEnabled,
  searchEnabled,
  additionalLinks,
}: Props) => {
  const widgetRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLUListElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  const gorgiasChatActive = useStore((state) => state.gorgiasActive)
  const setGorgiasChatActive = useStore((state) => state.setGorgiasActive)

  const handleHelpButtonClick = () => {
    setShowMenu((s) => !s)
  }

  const handleChatPress = () => {
    const { current: widget } = widgetRef
    const chatButton = document.querySelector(
      'iframe#chat-button'
    ) as HTMLDivElement

    if (chatButton && window.GorgiasChat && widget) {
      chatButton.style.display = 'unset'
      window.GorgiasChat.open()
    }

    setShowMenu(false)
  }

  const transitions = useTransition(showMenu, {
    from: {
      opacity: 0,
      y: '10%',
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    leave: {
      opacity: 0,
      y: '10%',
    },
  })

  useClickOutside(
    {
      current: [menuRef.current, widgetRef.current],
    },
    () => {
      setShowMenu(false)
    }
  )

  useEffect(() => {
    if (chatEnabled && gorgiasChatActive) {
      handleChatPress()
      /**
       *  Once opened, reset the reactive variable
       *  so we can open the chat again if it changes.
       */
      setGorgiasChatActive(false)
    }
  }, [chatEnabled, gorgiasChatActive, setGorgiasChatActive])

  return (
    <HelpWidgetContainer ref={widgetRef}>
      <HelpButton type="button" onClick={handleHelpButtonClick}>
        Support
      </HelpButton>
      {transitions((styles, item) =>
        item ? (
          <HelpMenu ref={menuRef} style={styles}>
            {Array.isArray(additionalLinks) &&
              additionalLinks.map((link) => (
                <HelpMenuItem key={link._key}>
                  <HelpMenuButton
                    as="a"
                    href={makeUrlFromType(link._type, [
                      link?.category ?? link?.tag,
                      link?.slug,
                    ])}
                  >
                    {link.title}
                  </HelpMenuButton>
                </HelpMenuItem>
              ))}
            {/* <HelpMenuItem>
              <HelpMenuButton type="button">Accessibility</HelpMenuButton>
            </HelpMenuItem> */}
            {emailEnabled && (
              <HelpMenuItem>
                <HelpMenuButton as="a" href="mailto:support@cellular-goods.com">
                  Email us
                </HelpMenuButton>
              </HelpMenuItem>
            )}
            {chatEnabled && (
              <HelpMenuItem>
                <HelpMenuButton onClick={handleChatPress} type="button">
                  Chat with us
                </HelpMenuButton>
              </HelpMenuItem>
            )}
            {searchEnabled && (
              <HelpMenuItem>
                <HelpMenuButton type="button" onClick={onSearchClick}>
                  Search
                </HelpMenuButton>
              </HelpMenuItem>
            )}
          </HelpMenu>
        ) : null
      )}
    </HelpWidgetContainer>
  )
}

const HelpWidgetContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 50;

  ${MEDIA_QUERIES.desktopUp} {
    bottom: 4rem;
    right: 4rem;
  }
`

const HelpButton = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)};
  padding: 1rem 1.3rem 1.4rem 1.3rem;
  margin: 0;
  border: none;
  background-color: var(--white);
  min-width: 8rem;
  border-radius: 2.5rem;
  box-shadow: 0px 0.4rem 2.4rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 300ms ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: var(--lightBrown);
      transition: background-color 300ms ease-out;
    }
  }
`

const HelpMenu = styled(animated.ul)`
  box-shadow: 0px 0.4rem 2.4rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
  min-width: 20rem;
  position: absolute;
  bottom: calc(100% + 2rem);
  right: 0;
  background-color: var(--white);
`

const HelpMenuItem = styled.li``

const HelpMenuButton = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_14_400)};
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 1rem;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  background: var(--white);
`
