import styled from 'styled-components'
import FocusLock from 'react-focus-lock'
import { useRef } from 'react'
import mergeRefs from 'react-merge-refs'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { Sanity } from '@cellulargoods/types'

import {
  useDisableScroll,
  useClickOutside,
  useWindowResize,
} from '@cellulargoods/hooks'
import {
  FONT_STYLE_SOFIA_14_400,
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_28_500,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'
import { ASK_CEL } from 'queries/singletons/askCel'
import groq from 'groq'
import { useSanity } from 'hooks/useSanity'
import { CloseBtn, Heading, Media, Text } from '@cellulargoods/core'

export type ModalAskCelProps = {
  open?: boolean
  onCloseClick?: () => void
}

const query = groq`
  *[_type == "askCel"][0]{
    ${ASK_CEL}
  }
`

export const ModalAskCel = ({
  open = false,
  onCloseClick,
}: ModalAskCelProps) => {
  const askCelContainerRef = useRef<HTMLDivElement>(null!)

  const [measureRef, { height: containerHeight }] = useMeasure({
    polyfill: ResizeObserver,
    scroll: true,
  })

  const { height, width } = useWindowResize()

  useDisableScroll(Boolean(open))

  const handleClose = () => {
    if (onCloseClick) {
      onCloseClick()
    }
  }

  useClickOutside(
    {
      current: [askCelContainerRef.current],
    },
    handleClose
  )

  const [data] = useSanity<Sanity.AskCel>({
    query,
  })

  return (
    <AskCelBackground
      open={open}
      style={{
        height: `${height}px`,
        width: `${width}px`,
        alignItems: height < containerHeight ? 'flex-start' : 'center',
      }}
      aria-hidden={!open}
    >
      <AskCelContainer ref={mergeRefs([askCelContainerRef, measureRef])}>
        <FocusLock disabled={!open} className="focus">
          {data ? (
            <AskCelGrid>
              <ModalCloseButton size={32} onClick={handleClose} />
              <MediaContainer>
                {data.media && data.media.length > 0 ? (
                  <Media
                    {...data.media[0]}
                    sizes={['100vw', null, null, '50vw']}
                  />
                ) : null}
              </MediaContainer>
              <TextContainer>
                <Heading fontStyle={FONT_STYLE_SOFIA_28_500}>
                  {data.heading}
                </Heading>
                <Text fontStyle={FONT_STYLE_SOFIA_14_400}>{data.copy}</Text>
                <CtaLink
                  href="https://chat.flexlabs.ai/cellular-goods"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.cta}
                </CtaLink>
              </TextContainer>
            </AskCelGrid>
          ) : (
            ''
          )}
        </FocusLock>
      </AskCelContainer>
    </AskCelBackground>
  )
}

const AskCelBackground = styled.dialog`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border: none;
  padding: 0;
  margin: 0;
  overflow: scroll;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  &:not([open]) {
    display: none;
  }
`

const AskCelContainer = styled.div`
  .focus {
    height: 100%;
    width: 100%;
  }

  background: var(--white);
  width: 90%;
  height: auto;
  max-width: 70rem;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    height: 45rem;
    background-color: var(--white);

    .focus {
      width: auto;
    }
  }
`

const AskCelGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  ${MEDIA_QUERIES.desktopUp} {
    grid-template-columns: 1fr 1fr;
  }
`

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const TextContainer = styled.div`
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const CtaLink = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)};
  display: block;
  background: var(--black);
  color: var(--white);
  padding: 0.8em;
  width: 100%;
  text-decoration: none;
  margin-top: 1rem;
`

const ModalCloseButton = styled(CloseBtn)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 10;
  background-color: var(--white);
  transition: background-color 300ms ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: var(--lightGrey);
      transition: background-color 300ms ease-out;
    }
  }
`
