import groq from 'groq'
import { MEDIA } from '../utils/media'
import { CTA_BUTTON } from '../utils/ctaButton'

export const FUNCTIONAL_IMAGE = groq`
  _key,
  _type,
  title,
  text,
  CTAButton {
    ${CTA_BUTTON}
  },
  media[]{
    ${MEDIA}
  }
`
