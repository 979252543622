import groq from 'groq'

import { MEDIA, PAGE_SETTINGS } from '@cellulargoods/services'

export const ASK_CEL = groq`
  _id,
  title,
  heading,
  copy,
  cta,
  PageSettings {
      ${PAGE_SETTINGS}
  },
  media[]{
      ${MEDIA}
  },
  modalShowingDuration
`
