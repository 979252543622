import groq from 'groq'
import { MEDIA } from '../utils/media'

export const CATEGORY_ACCOUNCEMENT = groq`
  _type,
  _key,
  pillText,
  announcement,
  media[]{
    ${MEDIA}
  }
`
