import groq from 'groq'
import { IMAGE } from '../utils/image'

export const PRODUCT_FRAGMENT = groq`
  _id,
  title,
  status,
  hidePrice,
  preorderText,
  soldoutText,
  interestText,
  description,
  ukOnly,
  productType[],
  image {
    ${IMAGE}
  },
  showReviews,
  isNew,
  customTag,
  customTagUs,
  customTagEu,
  hasComparePrice,
  strength {
    show,
    value,
  },
  product -> {
    id,
    price,
    compareAtPrice,
    title,
    productType,
    variants[] {
      title,
      id,
      price,
      quantityAvailable,
      inStock,
    },
  },
  slug {
    current,
  },
  category->{
    slug {
      current,
    },
  },
`
