import { animated, useSpring } from '@react-spring/web'
import FocusLock from 'react-focus-lock'
import styled from 'styled-components'
import { MagnifyingGlass } from 'phosphor-react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import {
  WIDTHS,
  FONT_STYLE_SOFIA_14_400,
  MEDIA_QUERIES,
  getFontStyles,
  FONT_STYLE_SOFIA_16_500,
  styleVisuallyHidden,
} from '@cellulargoods/styles'
import {
  useWindowResize,
  useDisableScroll,
  useClickOutside,
  useKeyPress,
} from '@cellulargoods/hooks'
import { CloseBtn, Button, ButtonTypes, Bg, Text } from '@cellulargoods/core'

import { useAlgoliaSearch } from 'hooks/useAlgoliaSearch'

import { SearchHints } from './SearchHint'
import { SearchHit } from './SearchHit'

interface Props {
  isActive: boolean
  onClose: () => void
}

/**
 * This component is the SearchOverlay for *desktop* only.
 *
 * For mobile we use the `<MobileSearch />` which is situated within the Mobile navbar.
 */
const SearchOverlay = ({ isActive, onClose }: Props) => {
  const { push } = useRouter()
  const searchContainerRef = useRef<HTMLDivElement>(null!)
  const [isDisplaying, setIsDisplaying] = useState(isActive)

  const [value, setValue] = useState('')
  const [containerOpacity, setContainerOpacity] = useState(0)

  const { width, height } = useWindowResize()

  useDisableScroll(isActive)

  const hits = useAlgoliaSearch(value, {
    searchOnEmptyValue: false,
  })

  const { opacity, y } = useSpring({
    opacity: isActive ? 1 : 0,
    y: isActive ? '0%' : '-100%',
    onStart: () => {
      if (isActive) {
        setIsDisplaying(true)
      }
    },
    onRest: () => {
      /**
       * If `isActive` is false on rest,
       * it means we have closed the SearchOverlay,
       * We want to wait until the animation has come to a rest before setting the value
       * back to `""` otherwise there is a jump in the component height.
       */

      if (!isActive) {
        setValue('')
        setIsDisplaying(false)
      }
    },
  })

  const handleClose = () => {
    onClose()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleHintClick = (hint: string) => {
    setValue(hint)
  }

  const handleViewAllClick = () => {
    push(`/search?q=${encodeURIComponent(value)}`)
    onClose()
  }

  useClickOutside(
    {
      current: [searchContainerRef.current],
    },
    handleClose
  )

  useKeyPress('Escape', handleClose)

  /**
   * The desktop search overlay is never shown on mobile,
   * We use the <MobileSearch /> component instead which is situated
   * within the Mobile Navigation.
   */
  useEffect(() => {
    if (isActive && width <= WIDTHS.desktop) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    setTimeout(() => {
      setContainerOpacity(1)
    }, 1000)
  }, [])

  return (
    <SearchBackground
      open={isDisplaying}
      style={{
        opacity,
        width: `${width}px`,
        height: `${height}px`,
      }}
      data-testid="search-overlay"
    >
      <FocusLock disabled={!isDisplaying}>
        <SearchContainer
          ref={searchContainerRef}
          $containerOpacity={containerOpacity}
          style={{
            y,
          }}
          data-testid="search-container"
        >
          <CloseBtnWrap>
            <CloseBtn onClick={handleClose} size={32} />
          </CloseBtnWrap>
          <SearchBarWrap $showBottom={value.length !== 0}>
            <MagnifyingGlass size={20} />
            <label>
              <LabelSpan>Search</LabelSpan>
              <Input
                placeholder="Search"
                value={value}
                onChange={handleChange}
              />
            </label>
            <DesktopCloseBtn>
              <CloseBtn onClick={handleClose} />
            </DesktopCloseBtn>
          </SearchBarWrap>
          {value.length === 0 && <SearchHints onHintClick={handleHintClick} />}
          {hits && hits.length > 0 && (
            <SearchListWrap>
              <SearchWrapUl data-testid="search-container-results">
                {hits.map((item, idx) => (
                  <SearchHit key={idx} {...item} handleClose={handleClose} />
                ))}
              </SearchWrapUl>
              <ButtonWrap>
                <Button
                  variant={ButtonTypes.PRIMARY}
                  bg={Bg.white}
                  onClick={handleViewAllClick}
                  isFullWidth
                >
                  View all suggestions
                </Button>
              </ButtonWrap>
            </SearchListWrap>
          )}
          {value.length !== 0 && hits.length === 0 ? (
            <SearchNoResults>
              <Text
                fontStyle={FONT_STYLE_SOFIA_16_500}
              >{`Sorry, we have no results for that. Try searching for something else.`}</Text>
            </SearchNoResults>
          ) : null}
        </SearchContainer>
      </FocusLock>
    </SearchBackground>
  )
}

export default SearchOverlay

const SearchBackground = styled(animated.dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border: none;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.2);

  &:not([open]) {
    display: none;
  }
`

const SearchContainer = styled(animated.div)<{
  $containerOpacity: number
}>`
  position: fixed;
  z-index: 0;
  background: white;
  width: 100%;
  opacity: ${({ $containerOpacity }) => $containerOpacity};
  border-bottom: solid 1px var(--black);
`

const SearchBarWrap = styled.div<{ $showBottom: boolean }>`
  border-top: 1px solid var(--lightGrey);
  border-bottom: ${(props) =>
    props.$showBottom ? '1px solid var(--lightGrey)' : 'none'};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  height: 80px;

  & > label {
    width: 100%;
  }

  ${MEDIA_QUERIES.desktopUp} {
    height: 100px;
    padding: 0 4rem;
  }
`

const CloseBtnWrap = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const DesktopCloseBtn = styled.div`
  display: none;
  ${MEDIA_QUERIES.desktopUp} {
    display: block;
  }
`

const Input = styled.input`
  ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
  border: none;
  margin-left: 10px;
  padding: 20px 0;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const SearchListWrap = styled.div`
  width: 100%;
  background: var(--softGrey);
`

const SearchWrapUl = styled.ul`
  padding: 30px 4rem 0;
  max-height: 450px;
  overflow-y: auto;
`

const ButtonWrap = styled.div`
  padding: 2rem 4rem 30px;
`

const SearchNoResults = styled.div`
  padding: 2rem;
  background-color: var(--softGrey);

  ${MEDIA_QUERIES.desktopUp} {
    padding: 3rem 4rem;
  }

  & > * {
    position: relative;
    bottom: 0.2rem;
  }
`

const LabelSpan = styled.span`
  ${styleVisuallyHidden}
`
