import { CSSProperties, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { NextSeo, NextSeoProps } from 'next-seo'

import { Maybe, Sanity } from '@cellulargoods/types'
import {
  useHeaderState,
  SiteHeader,
  Footer,
  createSeoFromPageMeta,
} from '@cellulargoods/core'

import { useShopifyCustomer } from 'hooks/useShopifyCustomer'
import { useCart } from 'hooks/useCart'

import { CartSideSheet } from 'components/SideSheets/SideSheetCart'
import { ModalAskCel } from 'components/Modals/ModalAskCel'
import { WidgetHelp } from 'components/Widgets/WidgetHelp'
import SearchOverlay from 'components/Search/Search'
import NavigationAuthModal from 'components/Modals/ModalNavigationAuth'

import {
  MODAL_COOKIE_DURATION_MINS,
  MODAL_SHOWING_DURATION_MS,
} from 'references/constants'

import { getCookie, setCookie } from 'helpers/cookies'
import { useStore } from 'store'

import groq from 'groq'
import { useSanity } from 'hooks/useSanity'

import {
  useExchangeRate,
  useFDAMessage,
  useUKDisclaimer,
} from '@cellulargoods/hooks'

export type SeoProps = {
  pageMeta?: Omit<Sanity.PageMeta, '_type'> & {
    slug?: string
  }
  additionalProps?: NextSeoProps
}

type LayoutProps = Partial<Sanity.GetLayoutDataPayload> & {
  children: React.ReactNode
  transparentHeader?: boolean
  seoProps?: SeoProps
  headerTheme?: Maybe<string>
  handleSetBuyWidgetSticky?: (stuck: boolean) => void
  style?: CSSProperties
}

const query = groq`
  *[_type == "askCel"][0]{
    modalShowingDuration
  }
`

const Layout = ({
  navbar,
  children,
  transparentHeader = false,
  footer,
  siteSettings,
  seoProps,
  theme,
  handleSetBuyWidgetSticky,
  style,
}: LayoutProps & {
  theme: string
}) => {
  const { SiteBanner: siteBanner, helpButton } = siteSettings ?? {}
  const { isLoggedIn, getCustomer } = useShopifyCustomer()
  const { push, pathname } = useRouter()
  const { getCheckout, totalItems } = useCart()
  const setCartIsOpen = useStore((state) => state.setCartIsOpen)

  const [mainPadding, setMainPadding] = useState(0)
  const [authModalActive, setAuthModalActive] = useState(false)
  const [askCelModalOpen, setNewsletterModalOpen] = useState(false)

  const setSearchOverlayActive = useStore(
    (state) => state.setSearchOverlayActive
  )

  const searchOverlayActive = useStore((state) => state.searchOverlayActive)
  const cartIsOpen = useStore((state) => state.cartIsOpen)

  const [headerHeights] = useHeaderState()

  const [data] = useSanity<Sanity.Newsletter>({
    query,
  })

  useEffect(() => {
    getCheckout()

    if (!isLoggedIn) {
      getCustomer()
    }
  }, [])

  const toggleCart = () => {
    setCartIsOpen(!cartIsOpen)
  }

  const handleAccountClick = () => {
    if (isLoggedIn) {
      push('/account')
    } else if (
      pathname !== '/signup' &&
      pathname !== '/login' &&
      pathname !== '/forgot'
    ) {
      /**
       * Disable the modal from appearing if
       * the user is on any of the following routes.
       */
      setAuthModalActive(true)
    } else if (pathname !== '/login') {
      push('/login')
    }
  }

  const handleAuthModalClose = () => setAuthModalActive(false)
  const handleSearchClose = () => {
    setSearchOverlayActive(false)
  }

  const handleSearchClick = () => {
    setSearchOverlayActive(true)
  }

  useEffect(() => {
    const handleResize = () => {
      const { bannerDismissed, bannerHeight, headerHeight } = headerHeights
      let heightVal = 0
      if (!transparentHeader && !bannerDismissed) {
        heightVal = bannerHeight + headerHeight
      } else if (!transparentHeader && bannerDismissed) {
        heightVal = headerHeight
      } else if (transparentHeader && !bannerDismissed) {
        heightVal = bannerHeight
      }
      if (heightVal !== mainPadding) {
        setMainPadding(heightVal)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [transparentHeader, headerHeights, mainPadding])

  useEffect(() => {
    /**
     * handles the time-in for the newsletter modal
     * then sets cookie that will expire in 30mins
     * so it shows again
     */
    let timeoutId: ReturnType<Window['setTimeout']> | null = null

    const handleTimeout = () => {
      setNewsletterModalOpen(true)
      setCookie('modalShown', true, MODAL_COOKIE_DURATION_MINS)
    }

    if (!getCookie('modalShown')) {
      if (data?.modalShowingDuration) {
        timeoutId = window.setTimeout(
          handleTimeout,
          MODAL_SHOWING_DURATION_MS * data.modalShowingDuration
        )
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [data?.modalShowingDuration])

  const handleNewsletterClose = () => {
    setNewsletterModalOpen(false)
  }

  return (
    <FlexWrap>
      <NextSeo
        {...createSeoFromPageMeta(seoProps?.pageMeta)}
        {...seoProps?.additionalProps}
      />
      {navbar ? (
        <SiteHeader
          {...navbar}
          onCartClick={toggleCart}
          onAccountClick={handleAccountClick}
          onSearchClick={handleSearchClick}
          siteBanner={siteBanner}
          theme={theme}
          transparent={transparentHeader}
          showRightSide
          cartQuantity={totalItems}
        />
      ) : null}
      <PageMain padding={mainPadding} data-testid="page-main" style={style}>
        {children}
      </PageMain>
      {footer ? (
        <Footer buyWidgetSticky={handleSetBuyWidgetSticky} {...footer} />
      ) : null}
      <SearchOverlay
        isActive={searchOverlayActive}
        onClose={handleSearchClose}
      />
      <ModalAskCel
        open={askCelModalOpen}
        onCloseClick={handleNewsletterClose}
      />
      <NavigationAuthModal
        isActive={authModalActive}
        onClose={handleAuthModalClose}
      />
      <CartSideSheet isActive={cartIsOpen} onCloseClick={toggleCart} />
      <WidgetHelp onSearchClick={handleSearchClick} {...helpButton} />
    </FlexWrap>
  )
}

export const SiteWebLayout = (props: LayoutProps) => {
  const [_a, setFDA] = useFDAMessage()
  const [_b, setUKDisclaimer] = useUKDisclaimer()
  const exchangeRates = useExchangeRate()

  const [header, setHeaderState] = useHeaderState({
    canBannerBeDismissed: Boolean(props.siteSettings?.SiteBanner?.dismissable),
  })

  useEffect(() => {
    setHeaderState({
      ...header,
      transparent:
        props.transparentHeader !== undefined
          ? props.transparentHeader
          : header.transparent,
      theme: props.headerTheme ?? 'black',
    })
  }, [props.headerTheme, props.transparentHeader])

  useEffect(() => {
    if (!!props?.siteSettings?.fdaDisclaimer) {
      setFDA(props.siteSettings.fdaDisclaimer)
    }

    if (!!props?.siteSettings?.usdExchangeRate) {
      exchangeRates['USD'][1](props?.siteSettings?.usdExchangeRate)
    }
    if (!!props?.siteSettings?.eurExchangeRate) {
      exchangeRates['EUR'][1](props?.siteSettings?.eurExchangeRate)
    }

    if (!!props?.siteSettings?.ukOnlyMessage) {
      setUKDisclaimer(props?.siteSettings?.ukOnlyMessage)
    }
  }, [props?.siteSettings, setUKDisclaimer, setFDA, exchangeRates])
  const desiredTheme = props.headerTheme ?? 'black'

  return <Layout {...props} theme={desiredTheme} />
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const PageMain = styled.main<{
  padding: number
}>`
  padding-top: ${(props) => `${props.padding}px`};
  flex-grow: 1;
  position: relative;
`
