import groq from 'groq'

import { ARTICLE_CARD } from './articleCard'

export const WHITEPAPER_HIGHLIGHTER = groq`
  _key,
  _type,
  title,
  text,
  whitepapers[]{
    ${ARTICLE_CARD}
  }
`
