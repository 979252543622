import groq from 'groq'
import { HEADER_TEXT } from './headerText'
import { PRODUCT_FRAGMENT } from './productFragment'

export const PRODUCT_CAROUSEL = groq`
  _key,
  _type,
  HeaderText {
    ${HEADER_TEXT}
  },
  products[]->{
    ${PRODUCT_FRAGMENT}
  }
`
