import groq from 'groq'

import { ARTICLE_CAROUSEL } from './articleCarousel'
import { ARTICLES_GRID } from './articleGrid'
import { ARTICLE_HIGHLIGHTER } from './articleHighlighter'
import { BENEFITS_BANNER } from './benefitsBanner'
import { CATEGORY_ACCOUNCEMENT } from './categoryAnnouncement'
import { COMPARISON_TABLE } from './comparisonTable'
import { FAQ_ACCORDION } from './faqAccordion'
import { FULL_BLEED } from './fullBleed'
import { FULL_LEFT_RIGHT } from './fullLeftRight'
import { FUNCTIONAL_IMAGE } from './functionalImage'
import { HEADER_TEXT } from './headerText'
import { HERO_IMAGE } from './heroImage'
import { INSTAGRAM_CAROUSEL } from './instagramCarousel'
import { LOGOS_BANNER } from './logosBanner'
import { LOGOS_BANNER_WITH_QUOTE } from './logosBannerWithQuote'
import { PORTRAIT_CAROUSEL } from './portraitCarousel'
import { PRODUCT_CAROUSEL } from './productCarousel'
import { RELATED_LINKS } from './relatedLinks'
import { RELATED_PRODUCTS } from './relatedProducts'
import { REVIEW_CAROUSEL } from './reviewCarousel'
import { REVIEW_MODULE } from './reviewModule'
import { SHOPPING_DIRECT_BANNER } from './shoppingDirectBanner'
import { SOCIALS_CHANNEL_PROMOTION } from './socialChannelsPromotion'
import { SPOTLIGHT } from './spotlight'
import { STORY_INTRO } from './storyIntro'
import { TEAM_GRID } from './teamGrid'
import { THREE_LINK_GRID } from './threeLinkGrid'
import { TICKER } from './ticker'
import { TWO_COLUMN_GRID } from './twoColumnGrid'
import { TWO_IMAGE_AND_TEXT } from './twoImageAndText'
import { UGC_CAROUSEL } from './ugcCarousel'
import { WHITEPAPER_HIGHLIGHTER } from './whitepaperHighlighter'

const componentsThatAreInstantReferces = groq`
  _type == "reference" => @ -> {
    (_type == "ShoppingDirectBanner") => {
      ${SHOPPING_DIRECT_BANNER}
    },
  },
`

/**
 * GraphQL Doesn't allow you to nest fragments correctly
 * so this is the best approach I've found.
 *
 * All Fragments will live inside this variable which is then passed into queries
 *
 * As there individual fragment:
 *
 * ```
 * CMSComponents {
 *    ...CTAButtonFragment
 *    ...OtherFragmentName
 * }
 * ```
 *
 */
export const ALL_CMS_COMPONENTS = groq`
  ${componentsThatAreInstantReferces}

 (_type == "ArticleCarousel") => {
  ${ARTICLE_CAROUSEL}
 },
 (_type == "ArticlesGrid") => {
  ${ARTICLES_GRID}
 },
 (_type == "ArticleHighlighter") => {
  ${ARTICLE_HIGHLIGHTER}
 },
 (_type == "BenefitsBanner") => {
  ${BENEFITS_BANNER}
 },
 (_type == "CategoryAnnouncement") => {
   ${CATEGORY_ACCOUNCEMENT}
 },
 (_type == "ComparisonTable") => {
   ${COMPARISON_TABLE}
 },
 (_type == "FaqAccordion") => {
   ${FAQ_ACCORDION}
 },
 (_type == "FullBleedComponent") => {
   ${FULL_BLEED}
 },
 (_type == "FullLeftRightComponent") => {
  ${FULL_LEFT_RIGHT}
 },
 (_type == "FunctionalImage") => {
   ${FUNCTIONAL_IMAGE}
 },
 (_type == "HeaderText") => {
   ${HEADER_TEXT}
 },
 (_type == "HeroImage") => {
   ${HERO_IMAGE}
 },
 (_type == "InstagramCarousel") => {
  ${INSTAGRAM_CAROUSEL}
 },
 (_type == "LogosBanner") => {
   ${LOGOS_BANNER}
 },
 (_type == "LogosBannerLargeWithQuote") => {
   ${LOGOS_BANNER_WITH_QUOTE}
 },
 (_type == "PortraitCarousel") => {
   ${PORTRAIT_CAROUSEL}
 },
 (_type == "ProductCarousel") => {
   ${PRODUCT_CAROUSEL}
 },
 (_type == "ReviewCarousel") => {
   ${REVIEW_CAROUSEL}
 },
 (_type == "ReviewsModule") => {
   ${REVIEW_MODULE}
 },
 (_type == "RelatedLinks") => {
   ${RELATED_LINKS}
 },
 (_type == "RelatedProducts") => {
   ${RELATED_PRODUCTS}
 },
 (_type == "SocialChannelsPromotion") => {
   ${SOCIALS_CHANNEL_PROMOTION}
 },
 (_type == "Spotlight") => {
   ${SPOTLIGHT}
 },
 (_type == "StoryIntro") => {
   ${STORY_INTRO}
 },
 (_type == "TeamGrid") => {
   ${TEAM_GRID}
 },
 (_type == "ThreeLinkGrid") => {
   ${THREE_LINK_GRID}
 },
 (_type == "Ticker") => {
   ${TICKER}
 },
 (_type == "TwoColumnGrid") => {
   ${TWO_COLUMN_GRID}
 },
 (_type == "TwoImageAndText") => {
   ${TWO_IMAGE_AND_TEXT}
 },
 (_type == "UGCCarousel") => {
   ${UGC_CAROUSEL}
 },
 (_type == "WhitepaperHighlighter") => {
   ${WHITEPAPER_HIGHLIGHTER}
 },
`

export const INTERSTITIAL_CMS_COMPONENTS = groq`
  ${componentsThatAreInstantReferces}

(_type == "ArticleCarousel") => {
 ${ARTICLE_CAROUSEL}
},
(_type == "ArticlesGrid") => {
 ${ARTICLES_GRID}
},
(_type == "ArticleHighlighter") => {
 ${ARTICLE_HIGHLIGHTER}
},
(_type == "BenefitsBanner") => {
 ${BENEFITS_BANNER}
},
(_type == "CategoryAnnouncement") => {
  ${CATEGORY_ACCOUNCEMENT}
},
(_type == "ComparisonTable") => {
  ${COMPARISON_TABLE}
},
(_type == "FaqAccordion") => {
  ${FAQ_ACCORDION}
},
(_type == "FullBleedComponent") => {
  ${FULL_BLEED}
},
(_type == "FullLeftRightComponent") => {
 ${FULL_LEFT_RIGHT}
},
(_type == "FunctionalImage") => {
  ${FUNCTIONAL_IMAGE}
},
(_type == "HeaderText") => {
  ${HEADER_TEXT}
},
(_type == "HeroImage") => {
  ${HERO_IMAGE}
},
(_type == "InstagramCarousel") => {
 ${INSTAGRAM_CAROUSEL}
},
(_type == "LogosBanner") => {
  ${LOGOS_BANNER}
},
(_type == "LogosBannerLargeWithQuote") => {
  ${LOGOS_BANNER_WITH_QUOTE}
},
(_type == "PortraitCarousel") => {
  ${PORTRAIT_CAROUSEL}
},
(_type == "ReviewCarousel") => {
  ${REVIEW_CAROUSEL}
},
(_type == "ReviewsModule") => {
  ${REVIEW_MODULE}
},
(_type == "RelatedLinks") => {
  ${RELATED_LINKS}
},
(_type == "Spotlight") => {
  ${SPOTLIGHT}
},
(_type == "StoryIntro") => {
  ${STORY_INTRO}
},
(_type == "TeamGrid") => {
  ${TEAM_GRID}
},
(_type == "Ticker") => {
  ${TICKER}
},
(_type == "TwoColumnGrid") => {
  ${TWO_COLUMN_GRID}
},
(_type == "TwoImageAndText") => {
  ${TWO_IMAGE_AND_TEXT}
},
(_type == "UGCCarousel") => {
  ${UGC_CAROUSEL}
},
(_type == "WhitepaperHighlighter") => {
  ${WHITEPAPER_HIGHLIGHTER}
},
`
