import groq from 'groq'

import { IMAGE } from './image'
import { MUX } from './mux'
import { YOUTUBE } from './youtube'

export const MEDIA = groq`
    (_type == 'MediaImage') => {
        ${IMAGE}
    },
    (_type == 'MediaMux') => {
        ${MUX}
    },
    (_type == 'MediaYoutube') => {
        ${YOUTUBE}
    },
`
