import groq from 'groq'
import { MEDIA } from '../utils/media'
import { CTA_BUTTON } from '../utils/ctaButton'

import { GENERIC_CARD } from './genericCard'
import { IMAGE_SLIDER } from './imageSlider'

const MEDIA_GRID_ITEM = groq`
  _key,
  _type,
  asset[]->{
    ${MEDIA}
  }
`

const TEXT_GRID_ITEM = groq`
  _key,
  _type,
  title,
  text,
  CTAButton {
    ${CTA_BUTTON}
  }
`

const COLUMN = groq`
  (_type == 'GenericCard') => {
    ${GENERIC_CARD}
  },
  (_type == 'TextGridItem') => {
    ${TEXT_GRID_ITEM}
  },
  (_type == 'MediaGridItem') => {
    ${MEDIA_GRID_ITEM}
  },
  (_type == 'ImageSlider') => {
    ${IMAGE_SLIDER}
  },
`

export const TWO_COLUMN_GRID = groq`
  _key,
  _type,
  shouldHaveBackground,
  columnReverseOnMobile,
  leftColumn[]{
    ${COLUMN}
  },
  rightColumn[]{
    ${COLUMN}
  }
`
