import groq from 'groq'

export const TAG = groq`
    _id,
    _key,
    title,
    slug {
      current
    }
`
