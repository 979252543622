import groq from 'groq'
import { MEDIA } from '../utils/media'
import { HEADER_TEXT } from './headerText'

export const FULL_BLEED = groq`
  _type,
  _key,
  position,
  isFullBleed,
  headingText {
    ${HEADER_TEXT}
  },
  media[]{
    ${MEDIA}
  }
`
