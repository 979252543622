import groq from 'groq'
import { MEDIA } from '../utils/media'
import { CTA_BUTTON } from '../utils/ctaButton'

export const STORY_INTRO = groq`
  _key,
  _type,
  title,
  body,
  CTAButton {
    ${CTA_BUTTON}
  },
  leftImage[]{
    ${MEDIA}
  },
  rightImage[]{
    ${MEDIA}
  }
`
