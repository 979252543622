import { useStore } from 'store'

export const useCart = () => {
  const cart = useStore((state) => state.checkout)
  const loading = useStore((state) => state.loading)

  const getCheckout = useStore((state) => state.getCheckout)
  const proceedCheckout = useStore((state) => state.proceedCheckout)
  const lineItemAdd = useStore((state) => state.lineItemAdd)
  const lineItemRemove = useStore((state) => state.lineItemRemove)
  const lineItemUpdate = useStore((state) => state.lineItemUpdate)
  const lineItemMutatingID = useStore((state) => state.lineItemMutating.id)
  const eligibleOffers = useStore((state) => state.findEligibleOffers)

  const totalItems =
    cart?.lineItems?.edges?.reduce((acc, val) => {
      acc += val?.node?.quantity
      return acc
    }, 0) || 0

  return {
    cart,
    loading,
    eligibleOffers,
    getCheckout,
    proceedCheckout,
    totalItems,
    lineItemAdd,
    lineItemRemove,
    lineItemUpdate,
    lineItemMutatingID,
  }
}
