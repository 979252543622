import groq from 'groq'

import { ARTICLE_CARD } from './articleCard'

export const ARTICLE_CAROUSEL = groq`
 _key,
 _type,
 background,
 title,
 articles[]{
   ${ARTICLE_CARD}
 }
`
