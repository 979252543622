import groq from 'groq'

import { NAVIGATION } from './documents/navigation'
import { FOOTER } from './documents/footer'
import { SITE_SETTINGS } from './documents/siteSettings'

export const LAYOUT = groq`
    "navbar": ${NAVIGATION},
    "footer": ${FOOTER},
    "siteSettings": ${SITE_SETTINGS},
`
